import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { authContext } from '../../Context/AuthContext';

function RiderLogin() {
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const [mobile_no, setMobile] = useState('');
  const [password, setPassword] = useState('');

  const { setLogged } = useContext(authContext);
  const { setUserName } = useContext(authContext);
  const { setUserMobile } = useContext(authContext);
  const { setUserEmail } = useContext(authContext);
  const { setUserRole } = useContext(authContext);

  const newDate = new Date();
  const year = newDate.getFullYear();

  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = () => {
    if (localStorage.getItem('usermescrowMobile')) {
      const userRole = localStorage.getItem('usermescrowRole');
      if (userRole === 'rider') {
        setLogged(true);
        navigate('/ridDashboard');
      }
    }
  };

  const userLogin = (e) => {
    e.preventDefault();
    if (mobile_no && password) {
      setLoading(true);
      Axios.post(
        'https://us-central1-mescrow.cloudfunctions.net/basicauth',
        { message: { mobile_no: mobile_no, password: password } },
        {
          headers: { 'Content-Type': 'application/json' },
        },
      )
        .then((res) => {
          // console.log(res.data);
          localStorage.setItem('usermescrow-Token', res.data.access_token);

          Axios.post(
            'https://us-east4-mescrow.cloudfunctions.net/login',
            {
              message: {
                access_token: res.data.access_token,
                mobile_no: mobile_no,
                Password: password,
              },
            },
            {
              headers: { 'Content-Type': 'application/json' },
            },
          )
            .then((res) => {
              // console.log('Posting Data From::', res.data);
              if (res.data.code === 500) {
                let userMobile = {
                  message: {
                    access_token: localStorage.getItem('usermescrow-Token'),
                    mobile_no: mobile_no,
                  },
                };
                Axios.post(
                  ' https://us-central1-mescrow.cloudfunctions.net/getuserinfo',
                  userMobile,
                  {
                    headers: { 'Content-Type': 'application/json' },
                  },
                )
                  .then((res) => {
                    // console.log('GEtting ROLE DATA From::', res.data.data);

                    if (res.data.data.user_type === 'rider') {
                      localStorage.setItem(
                        'usermescrowRole',
                        res.data.data.user_type,
                      );
                      localStorage.setItem(
                        'usermescrowMobile',
                        res.data.data.Mobile_no,
                      );
                      localStorage.setItem(
                        'usermescrowName',
                        res.data.data.Firstname,
                      );
                      localStorage.setItem(
                        'userEmail',
                        res.data.data.Email_address,
                      );
                      setLogged(true);
                      setUserName(res.data.data.Firstname);
                      setUserMobile(res.data.data.Mobile_no);
                      setUserEmail(res.data.data.Email_address);
                      setUserRole(res.data.data.user_type);

                      setLoading(false);
                      Swal.fire({
                        position: 'center',
                        icon: 'success',
                        title: 'Login Successfully',
                        showConfirmButton: false,
                        timer: 1500,
                      });

                      navigate('/ridDashboard');
                    } else {
                      setLoading(false);
                      Swal.fire({
                        position: 'center',
                        icon: 'error',
                        title: 'Can Only Access as Rider',
                        text: 'Only Riders Are Authorized to access This Page',
                      });
                    }
                  })
                  .catch((err) => {
                    setLoading(false);
                    console.log(err);
                  });
              }
            })
            .catch((err) => {
              console.log(err);
              setLoading(false);
              Swal.fire({
                title: 'Wrong Credentials!',
                text: 'Please check your Login credentials',
                icon: 'error',
                confirmButtonText: 'Ok',
              });
            });
        })
        .catch((err) => {
          setLoading(false);
          Swal.fire({
            title: 'Wrong Credentials!',
            text: 'Please check your Login credentials',
            icon: 'error',
            confirmButtonText: 'Ok',
          });
          console.log(err);
        });
    } else {
      Swal.fire({
        title: 'All Fields Are Required!',
        text: 'Please Fill All Fields',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className={`${
          !loading && 'hidden'
        } w-full h-full fixed  top-0 left-0 bg-white opacity-75 z-50`}
      >
        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
          <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
      </div>
      <main className="mt-0 transition-all duration-200 ease-in-out">
        <section>
          <div className="relative flex items-center min-h-screen p-0 overflow-hidden bg-center bg-cover">
            <div className="container z-1">
              <div className="flex flex-wrap -mx-3">
                <div className="flex flex-col w-full max-w-full px-3 mx-auto lg:mx-0 shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 xl:w-4/12">
                  <div className="relative flex flex-col min-w-0 break-words bg-transparent border-0 bg-white shadow-xl  lg:py4  rounded-2xl bg-clip-border">
                    <div className="p-6 pb-0 mb-0">
                      <Link to="/">
                        <img
                          src="/images/fav.png"
                          className=" transition-all duration-200  w-36 mx-auto"
                          alt="main_logo"
                        />
                      </Link>
                      <h5 className="font-bold md:text-2xl">Login as Rider</h5>
                    </div>
                    <div className="flex-auto p-6">
                      <form>
                        <div className="mb-4">
                          <input
                            type="tel"
                            placeholder="Mobile e.g 254712345678"
                            className="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                            value={mobile_no}
                            onChange={(e) => {
                              setMobile(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <input
                            type="password"
                            placeholder="Password"
                            className="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding p-3 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-fuchsia-300 focus:outline-none"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          />
                        </div>

                        <div className="text-center">
                          <button
                            onClick={userLogin}
                            type="submit"
                            className="inline-block w-full px-16 py-3.5 mt-6 mb-0 font-bold leading-normal text-center text-white align-middle transition-all bg-blue-500 border-0 rounded-lg cursor-pointer hover:-translate-y-px active:opacity-85 hover:shadow-xs text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25"
                          >
                            Sign in
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="absolute top-0 right-0 flex-col justify-center hidden w-6/12 h-full max-w-full px-3 pr-0 my-auto text-center flex-0 lg:flex">
                  <div className="relative flex flex-col justify-center h-full  px-24 m-4 overflow-hidden  rounded-xl riderLogBg">
                    <span className="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-blue-500 to-violet-500 opacity-30"></span>
                    <h4 className="z-20 mt-12  font-bold text-white">
                      Welcome to Mescrow
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="py-12">
        <div className="  mt-5 pb-4 ">
          <div className="md:flex justify-center items-center ">
            <img
              src="/images/logo.png"
              className="w-24 mx-auto md:mx-0"
              alt="Logo"
            />
            <p className="text-md pl-5 md:pl-1   text-gray-800  hover:text-gray-700 ">
              {' '}
              &copy; {year} Powered by{' '}
              <span className="font-bold"> Msimbo DevOps</span>
            </p>
            {/* <img src="/images/msimbo.PNG" className="w-16" alt="Logo" /> */}
          </div>
        </div>
      </footer>
    </>
  );
}

export default RiderLogin;
