import React, { useEffect, useState } from 'react';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function VenAddDelivery() {
  const userName = localStorage.getItem('usermescrowName');
  const userMobile = localStorage.getItem('usermescrowMobile');
  const userRole = localStorage.getItem('usermescrowRole');
  const token = localStorage.getItem('usermescrow-Token');
  const [ridersDisplay, setRidersDisplay] = useState([]);
  const [riderNumber, setRiderNumber] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    Axios.post(
      'https://us-central1-mescrow.cloudfunctions.net/getallriders',
      {
        message: {
          access_token: token,
          user_type: 'rider',
          Vendor_mobile_no: userMobile,
        },
      },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => {
        // console.log('GEtting Riders Data ::', res.data.data);
        const rids = res.data.data;
        const ridersarray = Object.values(rids);
        setRiderNumber(ridersarray[0].Mobile_no);
        setRidersDisplay(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const myRiders = Object.values(ridersDisplay);
  const dataRiders = myRiders.map((data, index) => {
    return (
      <option key={index} value={data.Mobile_no}>
        {data.Mobile_no}
      </option>
    );
  });

  // Items

  const [productList, setProductList] = useState([
    { name: '', quantity: 0, initialAmount: 0, totalAmount: 0 },
  ]);
  const [grandTotal, setGrandTotal] = useState(0);
  const [riderFare, setRiderFare] = useState(0);
  const [subTotal, setsubTotal] = useState(0);
  const [buyerNumber, setbuyerNumber] = useState('');
  const [address, setAddress] = useState('');
  const [datey, setDatey] = useState('');
  const [timey, setTimey] = useState('');

  useEffect(() => {
    updateGrandTotal();
  }, [productList, riderFare]);

  const handleProductNameChange = (event, index) => {
    const { value } = event.target;
    const list = [...productList];
    list[index].name = value;
    setProductList(list);
  };

  const handleProductQuantityChange = (event, index) => {
    const { value } = event.target;
    const list = [...productList];
    list[index].quantity = parseInt(value);
    list[index].totalAmount = list[index].quantity * list[index].initialAmount;
    setProductList(list);
  };

  const handleProductInitialAmountChange = (event, index) => {
    const { value } = event.target;
    const list = [...productList];
    list[index].initialAmount = parseInt(value);
    list[index].totalAmount = list[index].quantity * list[index].initialAmount;
    setProductList(list);
  };

  const handleAddRow = () => {
    setProductList([
      ...productList,
      { name: '', quantity: 0, initialAmount: 0, totalAmount: 0 },
    ]);
  };

  const handleDeleteRow = (index) => {
    const list = [...productList];
    list.splice(index, 1);
    setProductList(list);
  };

  const updateGrandTotal = () => {
    const fare = riderFare;
    const total = productList.reduce(
      (accumulator, currentValue) => accumulator + currentValue.totalAmount,
      0,
    );

    const grandtotal = parseInt(total) + parseInt(fare);
    setsubTotal(total);
    setGrandTotal(grandtotal);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (
      buyerNumber &&
      productList &&
      riderNumber &&
      userMobile &&
      subTotal &&
      riderFare &&
      timey &&
      datey &&
      address
    ) {
      setLoading(true);
      setRiderNumber(riderNumber);

      Axios.post('https://us-east4-mescrow.cloudfunctions.net/add_deliveries', {
        message: {
          access_token: token,
          buyer_mobile_no: buyerNumber,
          Product_name: productList,
          Rider_mobile_no: riderNumber,
          seller_mobile_no: userMobile,
          total_amount: subTotal,
          transport_fees: riderFare,
          Duration: `${datey} : ${timey}`,
          Location: address,
        },
      })
        .then((res) => {
          setLoading(false);
          console.log(res.data);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Delivery Added Successfully',
            showConfirmButton: false,
            timer: 1500,
          });
          navigate('/venDeliveries');
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'All Fields Are Required',
      });
    }
  };

  return (
    <>
      <div
        className={`${
          !loading && 'hidden'
        } w-full h-full fixed  top-0 left-0 bg-white opacity-75 z-50`}
      >
        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
          <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
      </div>
      <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
        <div class="w-full px-3 md:px-6 py-6 mx-auto">
          <div class="flex flex-wrap md:mt-60 -mx-3">
            <div class="w-full max-w-full px-3 shrink-0 md:w-8/12 md:flex-0">
              <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl   rounded-2xl bg-clip-border">
                <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                  <div class="">
                    <h4 class=" font-semibold my-3">Create New Delivery</h4>
                  </div>
                </div>

                <div class="flex-auto p-6">
                  <form>
                    <div class="flex flex-wrap -mx-3">
                      <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                        <div class="mb-4">
                          <label
                            for="username"
                            class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                          >
                            Buyer Number
                          </label>
                          <input
                            type="tel"
                            name="number"
                            value={buyerNumber}
                            onChange={(e) => {
                              setbuyerNumber(e.target.value);
                            }}
                            class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          />
                        </div>
                      </div>
                      <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                        <div class="mb-4">
                          <label
                            for="email"
                            class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                          >
                            Delivery address
                          </label>
                          <input
                            type="text"
                            name="address"
                            value={address}
                            onChange={(e) => {
                              setAddress(e.target.value);
                            }}
                            class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          />
                        </div>
                      </div>
                      <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                        <div class="mb-4">
                          <label
                            for="email"
                            class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                          >
                            Rider Number
                          </label>
                          <select
                            class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                            value={riderNumber}
                            onChange={(e) => {
                              setRiderNumber(e.target.value);
                            }}
                          >
                            {dataRiders}
                          </select>
                        </div>
                      </div>
                      <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                        <div class="mb-4">
                          <label
                            for="email"
                            class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                          >
                            Select Date of Delivery
                          </label>

                          <input
                            type="date"
                            name="date"
                            value={datey}
                            onChange={(e) => {
                              setDatey(e.target.value);
                            }}
                            class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          />
                        </div>
                      </div>

                      <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                        <div class="mb-4">
                          <label
                            for="email"
                            class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                          >
                            Select Time of Delivery
                          </label>

                          <input
                            type="time"
                            name="timey"
                            value={timey}
                            onChange={(e) => {
                              setTimey(e.target.value);
                            }}
                            class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          />
                        </div>
                      </div>

                      <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                        <div class="mb-4">
                          <label
                            for="email"
                            class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                          >
                            Rider's Fare
                          </label>
                          <input
                            type="number"
                            name="riderFare"
                            value={riderFare}
                            onChange={(e) => {
                              setRiderFare(e.target.value);
                            }}
                            class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          />
                        </div>
                      </div>
                    </div>

                    <hr class="h-px mx-0 my-4 bg-transparent border-0 opacity-25 bg-gradient-to-r from-transparent via-black/40 to-transparent   " />
                    <p class="leading-normal uppercase   text-sm">Add Item</p>

                    {productList.map((product, index) => (
                      <div class="flex flex-wrap -mx-3" key={index}>
                        <div class="w-full max-w-full px-3 shrink-0 md:w-1/4 md:flex-0">
                          <div class="mb-4">
                            <label
                              for="first name"
                              class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                            >
                              Item Name
                            </label>
                            <input
                              type="text"
                              class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              placeholder="Product name"
                              value={product.name}
                              onChange={(event) =>
                                handleProductNameChange(event, index)
                              }
                            />
                          </div>
                        </div>
                        <div class="w-full max-w-full px-3 shrink-0 md:w-1/4 md:flex-0">
                          <div class="mb-4">
                            <label
                              for="first name"
                              class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                            >
                              Quantity
                            </label>
                            <input
                              type="number"
                              class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              placeholder="Quantity"
                              value={product.quantity}
                              onChange={(event) =>
                                handleProductQuantityChange(event, index)
                              }
                            />
                          </div>
                        </div>
                        <div class="w-full max-w-full px-3 shrink-0 md:w-1/4 md:flex-0">
                          <div class="mb-4">
                            <label
                              for="first name"
                              class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                            >
                              Amount
                            </label>
                            <input
                              type="number"
                              class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              placeholder="Initial amount"
                              value={product.initialAmount}
                              onChange={(event) =>
                                handleProductInitialAmountChange(event, index)
                              }
                            />
                          </div>
                        </div>
                        <div class="w-full max-w-full px-3 shrink-0 md:w-1/4 md:flex-0">
                          <div class="mb-4">
                            <label
                              for="first name"
                              class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                            >
                              Total amount
                            </label>
                            <input
                              type="number"
                              class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              placeholder="Total amount"
                              value={product.totalAmount}
                              disabled
                            />
                          </div>
                        </div>
                        <div class="w-full max-w-full px-3 shrink-0 md:w-1/4 md:flex-0">
                          <div class="mt-8">
                            <button
                              onClick={() => handleDeleteRow(index)}
                              class="inline-block px-8 py-2  ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-red-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                            >
                              <i className="fas fa-trash-alt"></i> Del
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}

                    <div class="flex flex-wrap -mx-3">
                      <button
                        type="button"
                        class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-green-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                        onClick={handleAddRow}
                      >
                        Add Row +
                      </button>
                    </div>
                    <div>Sub Total For Items: {subTotal}</div>
                    <div>Rider's Fare: {riderFare}</div>
                    <div className="border-t-2 mt-1"></div>

                    <div className="font-bold my-2 text-xl ">
                      Grand Total:
                      <span className="text-green-600">{grandTotal}</span>{' '}
                    </div>
                    <button
                      onClick={handleSubmit}
                      type="submit"
                      class="inline-block px-8 py-2 my-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                    >
                      Add Delivery <i className="fas fa-check"></i>
                    </button>
                  </form>
                </div>
              </div>
            </div>
            <div class="w-full max-w-full px-3 mt-6 shrink-0 md:w-4/12 md:flex-0 md:mt-0">
              <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl   rounded-2xl bg-clip-border">
                <img
                  class="w-full rounded-t-2xl"
                  src="/images/bg-profile.jpg"
                  alt="profile cover "
                />
                <div class="flex flex-wrap justify-center -mx-3">
                  <div class="w-4/12 max-w-full px-3 flex-0 ">
                    <div class="mb-6 -mt-6 lg:mb-0 lg:-mt-16">
                      <img
                        class="h-auto max-w-full border-2 border-white border-solid rounded-circle"
                        src="/images/avatar8.png"
                        alt="profile "
                      />
                    </div>
                  </div>
                </div>

                <div class="flex-auto p-6 pt-0">
                  <div class="mt-6 text-center">
                    <h5 class=" ">{userName}</h5>

                    <div class="mt-6 mb-2 font-semibold leading-relaxed text-base uppercase  text-slate-700">
                      <i class="mr-2  ni ni-briefcase-24"></i>
                      {userRole}
                    </div>
                    <div class="">
                      <i class="mr-2  fas fa-phone"></i>
                      {userMobile}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default VenAddDelivery;
