const buyData = [
  {
    title: 'My Dashboard',
    icon: <i className="text-blue-600 fa-solid fa-home"></i>,
    link: '/buyDashboard',
  },
  {
    title: 'Transactions',
    icon: <i className="text-emerald-500 fas fa-piggy-bank"></i>,
    link: '/buyTransaction',
  },
  {
    title: 'Deliveries',
    icon: <i className="text-cyan-500 fas fa-shipping-fast"></i>,
    link: '/buyDeliveries',
  },
  {
    title: 'Disputes',
    icon: <i className="text-red-600 fas fa-user-shield"></i>,
    link: '/buyDisputes',
  },

  {
    title: 'Profile',
    icon: <i className="text-slate-700 fa-solid fa-user"></i>,
    link: '/buyProfile',
  },
];

export default buyData;
