import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { authContext } from '../Context/AuthContext';

import Swal from 'sweetalert2';

function Home() {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(!open);
  };
  const { setLogged } = useContext(authContext);

  const navigate = useNavigate();
  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = () => {
    if (localStorage.getItem('usermescrowMobile')) {
      const userRole = localStorage.getItem('usermescrowRole');
      if (userRole === 'seller') {
        setLogged(true);
        navigate('/venDashboard');
      }
    }
  };

  const newDate = new Date();
  const year = newDate.getFullYear();

  const [fname, setFname] = useState();
  const [lname, setLname] = useState();
  const [email, setEmail] = useState();
  const [mobile, setMobile] = useState();
  const [description, setDescription] = useState();

  const submitDetails = (e) => {
    e.preventDefault();
    if (fname && lname && email && mobile && description) {
      Swal.fire({
        position: 'center',
        icon: 'success',
        title: 'Submitted Successfully',
        text: 'We will contact you soon!',
      });

      setFname('');
      setLname('');
      setEmail('');
      setMobile('');
      setDescription('');
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'All Fields are required',
        text: 'Please fill all fields!',
      });
    }
  };

  return (
    <>
      <div className="bg-slate-900 w-full relative">
        <div className="bg-gradient-to-b from-violet-600/[.15] via-transparent">
          <header className="md:py-4 bg-slate-900  sticky top-0 z-40">
            <div className="px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
              <div className="flex items-center justify-between">
                <div className="shrink-0">
                  <Link to="/">
                    <img className="w-12 " src="/images/fav.png" alt="logo" />
                  </Link>
                </div>

                <div className="flex md:hidden">
                  <button
                    onClick={handleOpen}
                    type="button"
                    className="text-white bg-indigo-600 p-2 rounded-md  border-0"
                  >
                    <span className={`${open && 'hidden'} px-1`}>
                      <i className="fas fa-bars"></i>
                    </span>

                    <span className={`${!open && 'hidden'} px-1`}>
                      <i className="fas fa-close"></i>
                    </span>
                  </button>
                </div>

                <nav className="hidden ml-10 mr-auto space-x-10 lg:ml-20 lg:space-x-12 md:flex md:items-center md:justify-start  ">
                  <a
                    href="#home"
                    title=""
                    className="text-xl font-normal text-gray-400 transition-all duration-200 hover:text-white"
                  >
                    {' '}
                    Home{' '}
                  </a>

                  <a
                    href="#features"
                    title=""
                    className="text-xl font-normal text-gray-400 transition-all duration-200 hover:text-white"
                  >
                    {' '}
                    Features{' '}
                  </a>

                  <a
                    href="#work"
                    title=""
                    className="text-xl font-normal text-gray-400 transition-all duration-200 hover:text-white"
                  >
                    {' '}
                    How it Works{' '}
                  </a>

                  <a
                    href="#contact"
                    title=""
                    className="text-xl font-normal text-gray-400 transition-all duration-200 hover:text-white"
                  >
                    {' '}
                    Contact{' '}
                  </a>
                </nav>

                <div className="relative hidden md:items-center md:justify-center md:inline-flex group">
                  <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
                  <Link to="/buyerRegister">
                    <button
                      title=""
                      className="relative mx-5 inline-flex items-center justify-center px-6 py-2 text-base font-normal text-white bg-black hover:bg-indigo-900 hover:scale-105 border border-transparent rounded-full"
                    >
                      {' '}
                      <span className="mr-2"> Buyer</span>{' '}
                      <i className="fas fa-sign-out-alt"></i>
                    </button>
                  </Link>
                  <Link to="/vendorRegister">
                    <button
                      title=""
                      className="relative mx-5 inline-flex items-center justify-center px-6 py-2 text-base font-normal text-white bg-black hover:bg-indigo-900 hover:scale-105 border border-transparent rounded-full"
                    >
                      {' '}
                      <span className="mr-2">Seller</span>{' '}
                      <i className="fas fa-sign-out-alt"></i>
                    </button>
                  </Link>
                  <Link to="/riderLogin">
                    <button
                      title=""
                      className="relative mx-5 inline-flex items-center justify-center px-6 py-2 text-base font-normal text-white bg-black hover:bg-indigo-900 hover:scale-105 border border-transparent rounded-full"
                    >
                      <span className="mr-2"> Rider</span>{' '}
                      <i className="fas fa-sign-out-alt"></i>
                    </button>
                  </Link>
                </div>
              </div>

              <nav className={`${!open && 'hidden'} md:hidden`}>
                <div className="flex flex-col pt-8 pb-4 space-y-6 ">
                  <a
                    href="#home"
                    title=""
                    className="text-xl font-normal text-gray-400 transition-all duration-200 hover:text-white"
                  >
                    {' '}
                    Home{' '}
                  </a>

                  <a
                    href="#features"
                    title=""
                    className="text-xl font-normal text-gray-400 transition-all duration-200 hover:text-white"
                  >
                    {' '}
                    Features{' '}
                  </a>

                  <a
                    href="#work"
                    title=""
                    className="text-xl font-normal text-gray-400 transition-all duration-200 hover:text-white"
                  >
                    {' '}
                    How it Works{' '}
                  </a>

                  <a
                    href="#contact"
                    title=""
                    className="text-xl font-normal text-gray-400 transition-all duration-200 hover:text-white"
                  >
                    {' '}
                    Contact{' '}
                  </a>

                  <div className="relative   group">
                    <div>
                      <Link to="/buyerRegister">
                        <button className=" my-2  px-3 py-1 text-base font-normal text-white bg-gray-600 hover:bg-indigo-900 hover:scale-105 border border-transparent rounded-full">
                          {' '}
                          <span className="mr-2"> Buyer</span>{' '}
                          <i className="fas fa-sign-out-alt"></i>
                        </button>
                      </Link>
                    </div>
                    <div>
                      <Link to="/vendorRegister">
                        <button className=" my-2  px-3 py-1 text-base font-normal text-white bg-gray-600 hover:bg-indigo-900 hover:scale-105 border border-transparent rounded-full">
                          {' '}
                          <span className="mr-2"> Seller</span>{' '}
                          <i className="fas fa-sign-out-alt"></i>
                        </button>
                      </Link>
                    </div>

                    <div>
                      <Link to="/riderLogin">
                        <button className=" my-2  px-3 py-1 text-base font-normal text-white bg-gray-600 hover:bg-indigo-900 hover:scale-105 border border-transparent rounded-full">
                          {' '}
                          <span className="mr-2"> Rider</span>{' '}
                          <i className="fas fa-sign-out-alt"></i>
                        </button>
                      </Link>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </header>
          <div className="" id="home">
            <div className="">
              <section className=" py-12  sm:pb-16 lg:pb-20 xl:pb-24">
                <div className="px-4 mx-auto sm:px-6 lg:px-8 max-w-7xl">
                  <div className="relative">
                    <div className="lg:w-2/3">
                      <p className="text-sm font-normal tracking-widest text-gray-300 uppercase">
                        Secure Online Payment
                      </p>
                      <h1 className="mt-6 text-6xl font-normal text-white sm:mt-10   lg:text-9xl">
                        <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500">
                          Mescrow
                        </span>{' '}
                        <br />
                        <span className="lg:text-8xl text-4xl">
                          Safe Transactions
                        </span>
                      </h1>
                      <p className="max-w-lg mt-4 text-xl font-normal text-gray-400 sm:mt-8">
                        Get & Pay For What You Ordered.
                      </p>
                      <div className="relative inline-flex items-center justify-center mt-8 sm:mt-12 group">
                        <div className="absolute transition-all duration-200 rounded-full -inset-px bg-gradient-to-r from-cyan-500 to-purple-500 group-hover:shadow-lg group-hover:shadow-cyan-500/50"></div>
                        <a
                          href="#"
                          title=""
                          className="relative inline-flex items-center justify-center px-8 py-3 text-base font-normal text-white bg-black border border-transparent rounded-full"
                          role="button"
                        >
                          {' '}
                          Get in Touch{' '}
                        </a>
                      </div>
                    </div>

                    <div className="mt-8 md:absolute md:mt-0 md:top-32 lg:top-0 md:right-0">
                      <div className="relative">
                        <img
                          className="w-full max-w-xs mx-auto lg:max-w-lg xl:max-w-xl"
                          src="/images/escrow.png"
                          alt="landing"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </section>
            </div>
          </div>

          {/* vendor vs buyer */}

          <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div className="grid md:grid-cols-4 gap-6">
              <div className="">
                <h2 className="text-3xl  mb-10 font-bold lg:text-4xl text-white">
                  Seller
                </h2>
                <div className="flex">
                  <span className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border    shadow-sm mx-auto bg-slate-900 border-gray-700 text-gray-200">
                    <svg
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                      <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                    </svg>
                  </span>
                  <div className="ml-5 sm:ml-8">
                    <p className="mt-1 text-gray-400">
                      Register as a vendor on Mescrow and confirm your location
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <span className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border    shadow-sm mx-auto bg-slate-900 border-gray-700 text-gray-200">
                    <svg
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z" />
                      <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                  </span>
                  <div className="ml-5 sm:ml-8">
                    <p className="mt-1 text-gray-400">
                      Initiate a transaction based on agreed items and
                      specification with buyer
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <span className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200   shadow-sm mx-auto bg-slate-900  text-gray-200">
                    <svg
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                    </svg>
                  </span>
                  <div className="ml-5 sm:ml-8">
                    <p className="mt-1  text-gray-400">
                      Take a photo of the actual items to be delivered and paid
                      for as well as rider details and amounts to be paid
                    </p>
                  </div>
                </div>
              </div>

              <div className="md:col-span-2">
                <h2 className="text-3xl text-center mb-10 font-bold lg:text-6xl text-white">
                  VS
                </h2>
                <img
                  src="/images/vendor.png"
                  className=" transition-all duration-200  w-full mx-auto"
                  alt="main_logo"
                />
              </div>

              <div className="">
                <h2 className="text-3xl  mb-10 font-bold lg:text-4xl text-white">
                  Buyer
                </h2>
                <div className="flex">
                  <span className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border    shadow-sm mx-auto bg-slate-900 border-gray-700 text-gray-200">
                    <svg
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                      <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                    </svg>
                  </span>
                  <div className="ml-5 sm:ml-8">
                    <p className="mt-1 text-gray-400">
                      Register as a buyer on Mescrow and confirm your delivery
                      location
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <span className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border    shadow-sm mx-auto bg-slate-900 border-gray-700 text-gray-200">
                    <svg
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z" />
                      <path d="M5 6a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm4 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0z" />
                    </svg>
                  </span>
                  <div className="ml-5 sm:ml-8">
                    <p className="mt-1 text-gray-400">
                      Confirm to vendor you have registered to receive push STK
                      with agreed amounts. Details of the transaction advice
                      appear on your list of transactions on Mescrow
                    </p>
                  </div>
                </div>

                <div className="flex">
                  <span className="flex-shrink-0 inline-flex justify-center items-center w-[46px] h-[46px] rounded-full border border-gray-200   shadow-sm mx-auto bg-slate-900  text-gray-200">
                    <svg
                      className="w-5 h-5"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8.864.046C7.908-.193 7.02.53 6.956 1.466c-.072 1.051-.23 2.016-.428 2.59-.125.36-.479 1.013-1.04 1.639-.557.623-1.282 1.178-2.131 1.41C2.685 7.288 2 7.87 2 8.72v4.001c0 .845.682 1.464 1.448 1.545 1.07.114 1.564.415 2.068.723l.048.03c.272.165.578.348.97.484.397.136.861.217 1.466.217h3.5c.937 0 1.599-.477 1.934-1.064a1.86 1.86 0 0 0 .254-.912c0-.152-.023-.312-.077-.464.201-.263.38-.578.488-.901.11-.33.172-.762.004-1.149.069-.13.12-.269.159-.403.077-.27.113-.568.113-.857 0-.288-.036-.585-.113-.856a2.144 2.144 0 0 0-.138-.362 1.9 1.9 0 0 0 .234-1.734c-.206-.592-.682-1.1-1.2-1.272-.847-.282-1.803-.276-2.516-.211a9.84 9.84 0 0 0-.443.05 9.365 9.365 0 0 0-.062-4.509A1.38 1.38 0 0 0 9.125.111L8.864.046zM11.5 14.721H8c-.51 0-.863-.069-1.14-.164-.281-.097-.506-.228-.776-.393l-.04-.024c-.555-.339-1.198-.731-2.49-.868-.333-.036-.554-.29-.554-.55V8.72c0-.254.226-.543.62-.65 1.095-.3 1.977-.996 2.614-1.708.635-.71 1.064-1.475 1.238-1.978.243-.7.407-1.768.482-2.85.025-.362.36-.594.667-.518l.262.066c.16.04.258.143.288.255a8.34 8.34 0 0 1-.145 4.725.5.5 0 0 0 .595.644l.003-.001.014-.003.058-.014a8.908 8.908 0 0 1 1.036-.157c.663-.06 1.457-.054 2.11.164.175.058.45.3.57.65.107.308.087.67-.266 1.022l-.353.353.353.354c.043.043.105.141.154.315.048.167.075.37.075.581 0 .212-.027.414-.075.582-.05.174-.111.272-.154.315l-.353.353.353.354c.047.047.109.177.005.488a2.224 2.224 0 0 1-.505.805l-.353.353.353.354c.006.005.041.05.041.17a.866.866 0 0 1-.121.416c-.165.288-.503.56-1.066.56z" />
                    </svg>
                  </span>
                  <div className="ml-5 sm:ml-8">
                    <p className="mt-1  text-gray-400">
                      Complete transaction by accepting STK push from Vendor to
                      start the delivery process
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>

          {/* About us Video */}

          <div className="relative overflow-hidden" id="features">
            <div className="max-w-[85rem] mx-auto px-4 sm:px-6 lg:px-8 py-10">
              <div className="mt-10 relative max-w-5xl mx-auto">
                <div className="w-full bg-video object-cover h-full  bg-no-repeat bg-center bg-cover rounded-xl"></div>

                <div>
                  <video controls crossorigin playsinline>
                    <source
                      src="/images/msimboadvert.mp4"
                      type="video/mp4"
                      size="1080"
                    />
                  </video>
                </div>

                <div className="absolute bottom-12 -left-20 -z-[1] w-48 h-48 bg-gradient-to-b from-orange-500  p-px rounded-lg to-slate-900">
                  <div className=" w-48 h-48 rounded-lg bg-slate-900"></div>
                </div>

                <div className="absolute -top-12 -right-20 -z-[1] w-48 h-48 bg-gradient-to-t from-blue-600 to-cyan-400 p-px rounded-full">
                  <div className=" w-48 h-48 rounded-full bg-slate-900"></div>
                </div>
              </div>
            </div>
          </div>

          {/* dashboard */}

          <div className="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <img
              src="/images/mesc.PNG"
              className=" transition-all duration-200  w-full mx-auto"
              alt="main_logo"
            />
          </div>

          {/* stats */}
          <div class="max-w-[85rem] px-4 md:py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto">
            <div class="mt-20 text-center md:grid md:gap-6 md:grid-cols-2 sm:gap-12 lg:grid-cols-3 lg:gap-8">
              <div className="mb-10">
                <h4 class="text-lg sm:text-xl font-semibold  text-gray-200">
                  Accuracy rate
                </h4>
                <p class="mt-2 sm:mt-3 text-5xl sm:text-8xl  text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500">
                  99.95%
                </p>
                <p class="mt-1 text-gray-500">Secured Transactions</p>
              </div>

              <div className="mb-10">
                <h4 class="text-lg sm:text-xl font-semibold  text-gray-200">
                  Our Team Support
                </h4>
                <p class="mt-2 sm:mt-3 text-5xl sm:text-8xl  text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500">
                  24/7
                </p>
                <p class="mt-1 text-gray-500">Our Team Support</p>
              </div>

              <div className="mb-10">
                <h4 class="text-lg sm:text-xl font-semibold  text-gray-200">
                  Happy customer
                </h4>
                <p class="mt-2 sm:mt-3 text-5xl sm:text-8xl  text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500">
                  85%
                </p>
                <p class="mt-1 text-gray-500">This year alone</p>
              </div>
            </div>
          </div>

          <div
            class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
            id="work"
          >
            <div class="grid sm:grid-cols-2  items-center gap-6 md:gap-10">
              <img
                src="/images/shop.png"
                class="w-full relative z-10"
                alt="work"
                width={500}
                height={500}
              />
              <div class="w-full h-full  shadow-lg rounded-lg p-5 hover:bg-gray-700 bg-slate-900">
                <div class="md:flex items-center gap-x-4 mb-3">
                  <div class="inline-flex justify-center items-center w-[62px] h-[62px] rounded-full border-4  border-blue-900 bg-blue-800">
                    <svg
                      class="w-6 h-6  text-blue-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zm4 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3zM5.5 7a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm.5 6a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z" />
                      <path d="M16 8c0 3.15-1.866 2.585-3.567 2.07C11.42 9.763 10.465 9.473 10 10c-.603.683-.475 1.819-.351 2.92C9.826 14.495 9.996 16 8 16a8 8 0 1 1 8-8zm-8 7c.611 0 .654-.171.655-.176.078-.146.124-.464.07-1.119-.014-.168-.037-.37-.061-.591-.052-.464-.112-1.005-.118-1.462-.01-.707.083-1.61.704-2.314.369-.417.845-.578 1.272-.618.404-.038.812.026 1.16.104.343.077.702.186 1.025.284l.028.008c.346.105.658.199.953.266.653.148.904.083.991.024C14.717 9.38 15 9.161 15 8a7 7 0 1 0-7 7z" />
                    </svg>
                  </div>
                  <div class="flex-shrink-0">
                    <h3 class="block text-lg font-semibold  text-white">
                      Vendor(Seller)
                    </h3>
                  </div>
                </div>
                <p class=" text-gray-400">
                  It starts with the Vendor(Seller), who initiate the process by
                  filling all the products of the customer with all their prices
                  and quantities. Then He or She attaches the rider who is
                  available to deliver the goods to the buyer. Once done he or
                  she submits the order which will be processed.
                </p>
              </div>

              <div class="w-full h-full  shadow-lg rounded-lg p-5 hover:bg-gray-700 bg-slate-900">
                <div class="md:flex items-center gap-x-4 mb-3">
                  <div class="inline-flex justify-center items-center w-[62px] h-[62px] rounded-full border-4  border-blue-900 bg-blue-800">
                    <svg
                      class="w-6 h-6  text-blue-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path
                        fill-rule="evenodd"
                        d="M8 0a.5.5 0 0 1 .447.276L8.81 1h4.69A1.5 1.5 0 0 1 15 2.5V11h.5a.5.5 0 0 1 0 1h-2.86l.845 3.379a.5.5 0 0 1-.97.242L12.11 14H3.89l-.405 1.621a.5.5 0 0 1-.97-.242L3.36 12H.5a.5.5 0 0 1 0-1H1V2.5A1.5 1.5 0 0 1 2.5 1h4.691l.362-.724A.5.5 0 0 1 8 0ZM2 11h12V2.5a.5.5 0 0 0-.5-.5h-11a.5.5 0 0 0-.5.5V11Zm9.61 1H4.39l-.25 1h7.72l-.25-1Z"
                      />
                    </svg>
                  </div>
                  <div class="flex-shrink-0">
                    <h3 class="block text-lg font-semibold  text-white">
                      Buyer
                    </h3>
                  </div>
                </div>
                <p class=" text-gray-400">
                  Once the order is processed, an MPESA STK_PUSH is prompt to
                  the buyer where he or she needs to pay for both the products
                  and the delivery fee.The Buyer may also reject the order or
                  file for a dispute if the products are not delivered to him or
                  her. Incase of dispute the money will be reversed back to the
                  buyer.
                </p>
              </div>
              <img
                src="/images/buyer.png"
                class="w-full relative z-10"
                alt="work"
                width={500}
                height={500}
              />
              <img
                src="/images/rider.png"
                class="w-full relative z-10"
                alt="work"
                width={500}
                height={500}
              />

              <div class="w-full h-full  shadow-lg rounded-lg p-5 hover:bg-gray-700 bg-slate-900">
                <div class="md:flex items-center gap-x-4 mb-3">
                  <div class="inline-flex justify-center items-center w-[62px] h-[62px] rounded-full border-4  border-blue-900 bg-blue-800  ">
                    <svg
                      class="w-6 h-6  text-blue-400"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M2.97 1.35A1 1 0 0 1 3.73 1h8.54a1 1 0 0 1 .76.35l2.609 3.044A1.5 1.5 0 0 1 16 5.37v.255a2.375 2.375 0 0 1-4.25 1.458A2.371 2.371 0 0 1 9.875 8 2.37 2.37 0 0 1 8 7.083 2.37 2.37 0 0 1 6.125 8a2.37 2.37 0 0 1-1.875-.917A2.375 2.375 0 0 1 0 5.625V5.37a1.5 1.5 0 0 1 .361-.976l2.61-3.045zm1.78 4.275a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0 1.375 1.375 0 1 0 2.75 0V5.37a.5.5 0 0 0-.12-.325L12.27 2H3.73L1.12 5.045A.5.5 0 0 0 1 5.37v.255a1.375 1.375 0 0 0 2.75 0 .5.5 0 0 1 1 0zM1.5 8.5A.5.5 0 0 1 2 9v6h12V9a.5.5 0 0 1 1 0v6h.5a.5.5 0 0 1 0 1H.5a.5.5 0 0 1 0-1H1V9a.5.5 0 0 1 .5-.5zm2 .5a.5.5 0 0 1 .5.5V13h8V9.5a.5.5 0 0 1 1 0V13a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V9.5a.5.5 0 0 1 .5-.5z" />
                    </svg>
                  </div>
                  <div class="flex-shrink-0">
                    <h3 class="block text-lg font-semibold  text-white">
                      Rider(Delivery Guy)
                    </h3>
                  </div>
                </div>
                <p class=" text-gray-400">
                  Once the customer has accept the order and payed, a
                  notification is sent to the rider, who needs to carry the
                  products from the seller and deliver them to the buyer. once
                  the rider delivers the goods and the buyer confirms it, the
                  money is now released and both the seller and the rider get
                  the shares of the money that was assign to them.
                </p>
              </div>
            </div>
          </div>

          {/* Contact Us */}
          <div
            class="max-w-[85rem] px-4 py-10 sm:px-6 lg:px-8 lg:py-14 mx-auto"
            id="contact"
          >
            <div class="max-w-xl mx-auto">
              <div class="text-center">
                <h1 class="text-3xl font-bold  sm:text-4xl text-white">
                  Contact us
                </h1>
                <p class="mt-1  text-gray-400">
                  We would love to talk about how we can help you.
                </p>
              </div>
            </div>

            <div class="mt-12 max-w-lg mx-auto">
              <div class="flex flex-col border rounded-xl p-4 sm:p-6 lg:p-8 border-gray-700">
                <h2 class="mb-8 text-xl font-semibold  text-gray-200">
                  Fill in the form
                </h2>

                <form>
                  <div class="grid gap-4 lg:gap-6">
                    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                      <div>
                        <label
                          for="hs-firstname-contacts-1"
                          class="block text-smfont-medium text-white"
                        >
                          First Name
                        </label>
                        <input
                          type="text"
                          name="hs-firstname-contacts-1"
                          id="hs-firstname-contacts-1"
                          class="py-3 px-4 block w-full  rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-700 border-gray-700 text-gray-400"
                          value={fname}
                          onChange={(e) => {
                            setFname(e.target.value);
                          }}
                        />
                      </div>

                      <div>
                        <label
                          for="hs-lastname-contacts-1"
                          class="block text-sm  font-medium text-white"
                        >
                          Last Name
                        </label>
                        <input
                          type="text"
                          name="hs-lastname-contacts-1"
                          id="hs-lastname-contacts-1"
                          class="py-3 px-4 block w-full  rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-700 border-gray-700 text-gray-400"
                          value={lname}
                          onChange={(e) => {
                            setLname(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div class="grid grid-cols-1 sm:grid-cols-2 gap-4 lg:gap-6">
                      <div>
                        <label
                          for="hs-email-contacts-1"
                          class="block text-sm  font-medium text-white"
                        >
                          Email
                        </label>
                        <input
                          type="email"
                          name="hs-email-contacts-1"
                          id="hs-email-contacts-1"
                          autocomplete="email"
                          class="py-3 px-4 block w-full  rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-700 border-gray-700 text-gray-400"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>

                      <div>
                        <label
                          for="hs-phone-number-1"
                          class="block text-sm  font-medium text-white"
                        >
                          Phone Number
                        </label>
                        <input
                          type="text"
                          name="hs-phone-number-1"
                          id="hs-phone-number-1"
                          class="py-3 px-4 block w-full rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-700 border-gray-700 text-gray-400"
                          value={mobile}
                          onChange={(e) => {
                            setMobile(e.target.value);
                          }}
                        />
                      </div>
                    </div>

                    <div>
                      <label
                        for="hs-about-contacts-1"
                        class="block text-sm  font-medium text-white"
                      >
                        Details
                      </label>
                      <textarea
                        id="hs-about-contacts-1"
                        name="hs-about-contacts-1"
                        rows="4"
                        class="py-3 px-4 block w-full  rounded-md text-sm focus:border-blue-500 focus:ring-blue-500 bg-slate-700 border-gray-700 text-gray-400"
                        value={description}
                        onChange={(e) => {
                          setDescription(e.target.value);
                        }}
                      ></textarea>
                    </div>
                  </div>

                  <div class="mt-6 grid">
                    <button
                      onClick={submitDetails}
                      type="submit"
                      class="inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-sm lg:text-base text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2  transition py-3 px-4 focus:ring-offset-gray-800"
                    >
                      Send inquiry
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>

          {/* Footer */}

          <footer className="">
            <div className="max-w-[85rem] py-10 px-4 sm:px-6 lg:px-8 lg:pt-20 mx-auto">
              <div className="md:grid  md:grid-cols-3  md:gap-6">
                <div className="col-span-full lg:col-span-1">
                  <img src="/images/logo.png" alt="logo" className="w-40" />

                  <p className="inline-flex gap-x-2 pt-2 text-gray-400 hover:text-gray-200">
                    Contact us today to learn more about how we can help your
                    business thrive in the digital age.
                  </p>
                </div>

                <div className="col-span-1">
                  <h4 className="font-semibold text-gray-100">Contacts</h4>

                  <div className="mt-3 grid space-y-3">
                    <p className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200">
                      Email: admin@mescrow.shop
                    </p>
                    <p className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200">
                      Tel: +254 716 00 37 92
                    </p>
                    <p className="inline-flex gap-x-2 text-gray-400 hover:text-gray-200">
                      Westpark Towers, 8th Floor, Suite 803 Mpesi Lane, Off
                      Mithithi Road Westlands, Nairobi
                    </p>
                  </div>
                </div>

                <div className="col-span-1">
                  <h4 className="font-semibold text-gray-100">Email Us</h4>

                  <form>
                    <div className="mt-4 flex flex-col items-center gap-2 sm:flex-row sm:gap-3 bg-white rounded-md p-2">
                      <div className="w-full">
                        <label className="sr-only">Subscribe</label>
                        <input
                          type="text"
                          id="hero-input"
                          name="hero-input"
                          className="py-3 px-4 block w-full border-transparent shadow-sm rounded-md focus:z-10 focus:border-blue-500 focus:ring-blue-500"
                          placeholder="Enter your email"
                        />
                      </div>
                      <button
                        type="button"
                        className="w-full sm:w-auto whitespace-nowrap inline-flex justify-center items-center gap-x-3 text-center bg-blue-600 hover:bg-blue-700 border border-transparent text-white font-medium rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-white transition py-3 px-4"
                      >
                        Subscribe
                      </button>
                    </div>
                  </form>
                </div>
              </div>

              <div className="mt-5 sm:mt-12 grid gap-y-2 sm:gap-y-0 sm:flex sm:justify-between sm:items-center">
                <div className="flex justify-between items-center">
                  <p className="text-lg text-gray-400">
                    © {year} Msimbo Devs. All rights reserved.
                  </p>
                </div>

                <div>
                  <a
                    className="inline-flex justify-center items-center gap-x-3.5 w-10 h-10 text-center text-gray-200 hover:bg-white/[.1] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-gray-900 transition"
                    href="#"
                  >
                    <svg
                      className="w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                    </svg>
                  </a>
                  <a
                    className="inline-flex justify-center items-center gap-x-3.5 w-10 h-10 text-center text-gray-200 hover:bg-white/[.1] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-gray-900 transition"
                    href="#"
                  >
                    <svg
                      className="w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M15.545 6.558a9.42 9.42 0 0 1 .139 1.626c0 2.434-.87 4.492-2.384 5.885h.002C11.978 15.292 10.158 16 8 16A8 8 0 1 1 8 0a7.689 7.689 0 0 1 5.352 2.082l-2.284 2.284A4.347 4.347 0 0 0 8 3.166c-2.087 0-3.86 1.408-4.492 3.304a4.792 4.792 0 0 0 0 3.063h.003c.635 1.893 2.405 3.301 4.492 3.301 1.078 0 2.004-.276 2.722-.764h-.003a3.702 3.702 0 0 0 1.599-2.431H8v-3.08h7.545z" />
                    </svg>
                  </a>
                  <a
                    className="inline-flex justify-center items-center gap-x-3.5 w-10 h-10 text-center text-gray-200 hover:bg-white/[.1] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-gray-900 transition"
                    href="#"
                  >
                    <svg
                      className="w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z" />
                    </svg>
                  </a>
                  <a
                    className="inline-flex justify-center items-center gap-x-3.5 w-10 h-10 text-center text-gray-200 hover:bg-white/[.1] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-gray-900 transition"
                    href="#"
                  >
                    <svg
                      className="w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M8 0C3.58 0 0 3.58 0 8c0 3.54 2.29 6.53 5.47 7.59.4.07.55-.17.55-.38 0-.19-.01-.82-.01-1.49-2.01.37-2.53-.49-2.69-.94-.09-.23-.48-.94-.82-1.13-.28-.15-.68-.52-.01-.53.63-.01 1.08.58 1.23.82.72 1.21 1.87.87 2.33.66.07-.52.28-.87.51-1.07-1.78-.2-3.64-.89-3.64-3.95 0-.87.31-1.59.82-2.15-.08-.2-.36-1.02.08-2.12 0 0 .67-.21 2.2.82.64-.18 1.32-.27 2-.27.68 0 1.36.09 2 .27 1.53-1.04 2.2-.82 2.2-.82.44 1.1.16 1.92.08 2.12.51.56.82 1.27.82 2.15 0 3.07-1.87 3.75-3.65 3.95.29.25.54.73.54 1.48 0 1.07-.01 1.93-.01 2.2 0 .21.15.46.55.38A8.012 8.012 0 0 0 16 8c0-4.42-3.58-8-8-8z" />
                    </svg>
                  </a>
                  <a
                    className="inline-flex justify-center items-center gap-x-3.5 w-10 h-10 text-center text-gray-200 hover:bg-white/[.1] rounded-md focus:outline-none focus:ring-2 focus:ring-blue-600 focus:ring-offset-2 focus:ring-offset-gray-900 transition"
                    href="#"
                  >
                    <svg
                      className="w-4 h-4"
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3.362 10.11c0 .926-.756 1.681-1.681 1.681S0 11.036 0 10.111C0 9.186.756 8.43 1.68 8.43h1.682v1.68zm.846 0c0-.924.756-1.68 1.681-1.68s1.681.756 1.681 1.68v4.21c0 .924-.756 1.68-1.68 1.68a1.685 1.685 0 0 1-1.682-1.68v-4.21zM5.89 3.362c-.926 0-1.682-.756-1.682-1.681S4.964 0 5.89 0s1.68.756 1.68 1.68v1.682H5.89zm0 .846c.924 0 1.68.756 1.68 1.681S6.814 7.57 5.89 7.57H1.68C.757 7.57 0 6.814 0 5.89c0-.926.756-1.682 1.68-1.682h4.21zm6.749 1.682c0-.926.755-1.682 1.68-1.682.925 0 1.681.756 1.681 1.681s-.756 1.681-1.68 1.681h-1.681V5.89zm-.848 0c0 .924-.755 1.68-1.68 1.68A1.685 1.685 0 0 1 8.43 5.89V1.68C8.43.757 9.186 0 10.11 0c.926 0 1.681.756 1.681 1.68v4.21zm-1.681 6.748c.926 0 1.682.756 1.682 1.681S11.036 16 10.11 16s-1.681-.756-1.681-1.68v-1.682h1.68zm0-.847c-.924 0-1.68-.755-1.68-1.68 0-.925.756-1.681 1.68-1.681h4.21c.924 0 1.68.756 1.68 1.68 0 .926-.756 1.681-1.68 1.681h-4.21z" />
                    </svg>
                  </a>
                </div>
              </div>
            </div>
          </footer>
        </div>
      </div>
    </>
  );
}

export default Home;
