import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Footer from '../../dashboard/footer/Footer';
import Axios from 'axios';

function VenRiders() {
  const [riders, setRiders] = useState([]);
  const token = localStorage.getItem('usermescrow-Token');
  const userMobile = localStorage.getItem('usermescrowMobile');
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    Axios.post(
      'https://us-central1-mescrow.cloudfunctions.net/getallriders',
      {
        message: {
          access_token: token,
          user_type: 'rider',
          Vendor_mobile_no: userMobile,
        },
      },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => {
        // console.log('GEtting Riders Data ::', res.data.data);
        setRiders(res.data.data);
        setLoading(true);
      })
      .catch((err) => console.log(err));
  }, []);

  const myRiders = Object.values(riders);
  const dataRiders = myRiders.map((data, index) => {
    return (
      <tr
        key={index}
        className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
      >
        <td className="border-grey-light border hover:bg-gray-100 p-3">
          <input
            type="checkbox"
            className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded  "
          />
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <div className="flex flex-wrap flex-row items-center">
            <img
              className="h-8 w-8 rounded-full"
              src="/images/avatar8.png"
              alt="profile"
            />
          </div>
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/venViewRider/${data.Mobile_no}`}>{data.Firstname}</Link>
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          {' '}
          <Link to={`/venViewRider/${data.Mobile_no}`}>{data.Lastname}</Link>
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/venViewRider/${data.Mobile_no}`}>
            {data.Email_address}
          </Link>
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/venViewRider/${data.Mobile_no}`}>{data.Mobile_no}</Link>
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/venViewRider/${data.Mobile_no}`}>
            <span className="text-sm px-2 py-1 font-semibold leading-tight text-green-700 bg-green-100 rounded-full">
              Active
            </span>
          </Link>
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/venViewRider/${data.Mobile_no}`}>
            {data.Current_location}
          </Link>
        </td>

        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/venViewRider/${data.Mobile_no}`}>
            <button
              type="button"
              class="hidden my-2 px-8 py-2 font-bold leading-normal text-center text-white align-middle transition-all ease-in border-0 rounded-lg shadow-md cursor-pointer text-xs bg-cyan-500 lg:block tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
            >
              View{' '}
            </button>
          </Link>
        </td>
      </tr>
    );
  });

  return (
    <>
      <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
        <div class="w-full px-3 md:px-6 py-6 mx-auto">
          <div class="flex flex-wrap md:mt-64 -mx-3">
            <div class="flex-none w-full max-w-full px-3">
              <div class="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl  rounded-2xl bg-clip-border">
                <div class="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                  <h4 class=" font-semibold my-3">My Riders</h4>
                  <Link to="/venAddRider">
                    <button
                      type="button"
                      class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                    >
                      Add New Rider +
                    </button>
                  </Link>
                </div>
                <div class="flex-auto px-0 pt-0 pb-2">
                  <div class="p-0 overflow-x-hidden">
                    <table className="table-sorter table-bordered w-full text-left text-gray-600 ">
                      <thead>
                        <tr className="bg-gray-100  ">
                          <th
                            data-sortable="false"
                            className="hidden lg:table-cell"
                          >
                            <input
                              id="check_all"
                              type="checkbox"
                              className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded "
                            />
                          </th>
                          <th className="hidden lg:table-cell">Image</th>
                          <th className="hidden lg:table-cell">Firstname</th>
                          <th className="hidden lg:table-cell">Lastname</th>
                          <th className="hidden lg:table-cell">Email</th>
                          <th className="hidden lg:table-cell">Mobile</th>
                          <th className="hidden lg:table-cell">Status</th>
                          <th className="hidden lg:table-cell">Location</th>
                          <th
                            className="hidden lg:table-cell"
                            data-sortable="false"
                          >
                            Actions
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          dataRiders
                        ) : (
                          <tr className="flex justify-center  items-center h-40 w-full">
                            <td className="w-full mx-auto ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="50"
                                height="50"
                                fill="currentColor"
                                className="bi bi-arrow-repeat animate-spin"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                <path d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                              </svg>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}

export default VenRiders;
