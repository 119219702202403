import Axios from 'axios';
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Swal from 'sweetalert2';
import { authContext } from '../../Context/AuthContext';

function BuyerRegistration() {
  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [mobile_no, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [location, setLocation] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');
  const userType = 'buyer';
  const deliveryInfo = '';
  const deliveryTime = '';
  const storename = '';

  const { setLogged } = useContext(authContext);
  const { setUserName } = useContext(authContext);
  const { setUserMobile } = useContext(authContext);
  const { setUserEmail } = useContext(authContext);
  const { setUserRole } = useContext(authContext);

  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  const newDate = new Date();
  const year = newDate.getFullYear();

  const registerBuyer = (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      firstname &&
      lastname &&
      mobile_no &&
      email &&
      location &&
      password &&
      cpassword
    ) {
      Axios.post(
        'https://us-east4-mescrow.cloudfunctions.net/buyer_registration',
        {
          message: {
            Firstname: firstname,
            Lastname: lastname,
            Mobile_no: mobile_no,
            Email_address: email,
            Drop_off_location: location,
            More_information_on_delivery: deliveryInfo,
            Preferred_delivery_time: deliveryTime,
            confirm_Password: cpassword,
            Password: password,
            user_type: userType,
            store_name: storename,
          },
        },
      )
        .then((res) => {
          // console.log('Posting Data From::', res);
          localStorage.setItem('usermescrow-Token', res.data.access_token);

          let userMobile = {
            message: {
              access_token: res.data.access_token,
              mobile_no: mobile_no,
            },
          };

          Axios.post(
            ' https://us-central1-mescrow.cloudfunctions.net/getuserinfo',
            userMobile,
            {
              headers: { 'Content-Type': 'application/json' },
            },
          )
            .then((res) => {
              // console.log('GEtting ROLE DATA From::', res.data.data);

              if (res.data.data.user_type === 'buyer') {
                localStorage.setItem(
                  'usermescrowRole',
                  res.data.data.user_type,
                );
                localStorage.setItem(
                  'usermescrowMobile',
                  res.data.data.Mobile_no,
                );
                localStorage.setItem(
                  'usermescrowName',
                  res.data.data.Firstname,
                );
                localStorage.setItem('userEmail', res.data.data.Email_address);
                setLogged(true);
                setUserName(res.data.data.Firstname);
                setUserMobile(res.data.data.Mobile_no);
                setUserEmail(res.data.data.Email_address);
                setUserRole(res.data.data.user_type);
                setLoading(false);
                Swal.fire({
                  position: 'center',
                  icon: 'success',
                  title: 'Buyer Registered Successfully',
                  text: 'Your Account was Created Successfully',
                });

                navigate('/buyDashboard');
              }
            })
            .catch((err) => {
              setLoading(false);
              console.log(err.message);
            });
        })
        .catch((err) => {
          console.log(err.message);
          setLoading(false);
        });
    } else {
      Swal.fire({
        title: 'All Fields Are Required!',
        text: 'Please Fill All Fields',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  const checkLogin = () => {
    if (localStorage.getItem('usermescrowMobile')) {
      const userRole = localStorage.getItem('usermescrowRole');
      if (userRole === 'buyer') {
        setLogged(true);
        navigate('/buyDashboard');
      }
    }
  };

  return (
    <>
      <div
        className={`${
          !loading && 'hidden'
        } w-full h-full fixed  top-0 left-0 bg-white opacity-75 z-50`}
      >
        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
          <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
      </div>
      <main className="mt-0 transition-all duration-200 ease-in-out">
        <section>
          <div className="relative flex items-center min-h-screen p-0 overflow-hidden bg-center bg-cover">
            <div className="container z-1">
              <div className="flex flex-wrap -mx-3">
                <div className="flex flex-col w-full max-w-full md:px-3 mx-auto lg:mx-0 shrink-0 md:flex-0 md:w-7/12 lg:w-5/12 ">
                  <div className="relative z-0 flex flex-col min-w-0 break-words bg-white border-0 shadow-xl rounded-2xl bg-clip-border">
                    <div className="p-6 mb-0 text-center bg-white border-b-0 rounded-t-2xl">
                      <Link to="/">
                        <img
                          src="/images/fav.png"
                          className=" transition-all duration-200  w-36 mx-auto"
                          alt="main_logo"
                        />
                      </Link>
                      <h5 className="font-bold md:text-2xl">
                        Register Buyer Account
                      </h5>
                    </div>
                    <div className="flex-auto p-6">
                      <form>
                        <div className="mb-4">
                          <input
                            type="text"
                            className="placeholder:text-gray-500 text-sm focus:shadow-primary-outline leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-blue-500 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                            placeholder="Firstname"
                            aria-label="FName"
                            aria-describedby="name-addon"
                            value={firstname}
                            onChange={(e) => {
                              setFirstName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <input
                            type="text"
                            className="placeholder:text-gray-500 text-sm focus:shadow-primary-outline leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-blue-500 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                            placeholder="Lastname"
                            aria-label="LName"
                            aria-describedby="name-addon"
                            value={lastname}
                            onChange={(e) => {
                              setLastName(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <input
                            type="tel"
                            className="placeholder:text-gray-500 text-sm focus:shadow-primary-outline leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-blue-500 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                            placeholder="mobile: e.g 254712345678"
                            aria-label="mobile"
                            aria-describedby="name-addon"
                            value={mobile_no}
                            onChange={(e) => {
                              setMobile(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <input
                            type="email"
                            className="placeholder:text-gray-500 text-sm focus:shadow-primary-outline leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-blue-500 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                            placeholder="Email"
                            aria-label="Email"
                            aria-describedby="email-addon"
                            value={email}
                            onChange={(e) => {
                              setEmail(e.target.value);
                            }}
                          />
                        </div>

                        <div className="mb-4">
                          <input
                            type="text"
                            className="placeholder:text-gray-500 text-sm focus:shadow-primary-outline leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-blue-500 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                            placeholder="Location"
                            aria-label="location"
                            aria-describedby="location-addon"
                            value={location}
                            onChange={(e) => {
                              setLocation(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <input
                            type="password"
                            className="placeholder:text-gray-500 text-sm focus:shadow-primary-outline leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-blue-500 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                            placeholder="Password"
                            aria-label="Password"
                            aria-describedby="password-addon"
                            value={password}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          />
                        </div>
                        <div className="mb-4">
                          <input
                            type="password"
                            className="placeholder:text-gray-500 text-sm focus:shadow-primary-outline leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding py-2 px-3 font-normal text-gray-700 transition-all focus:border-blue-500 focus:bg-white focus:text-gray-700 focus:outline-none focus:transition-shadow"
                            placeholder="Confirm Password"
                            aria-label="Password"
                            aria-describedby="password-addon"
                            value={cpassword}
                            onChange={(e) => {
                              setCPassword(e.target.value);
                            }}
                          />
                        </div>
                        <div className="min-h-6 pl-7 mb-0.5 block">
                          <input
                            className="w-4.8 h-4.8 ease -ml-7 rounded-1.4 checked:bg-gradient-to-tl checked:from-blue-500 checked:to-violet-500 after:text-xxs after:font-awesome after:duration-250 after:ease-in-out duration-250 relative float-left mt-1 cursor-pointer appearance-none border border-solid border-slate-200 bg-white bg-contain bg-center bg-no-repeat align-top transition-all after:absolute after:flex after:h-full after:w-full after:items-center after:justify-center after:text-white after:opacity-0 after:transition-all after:content-['\f00c'] checked:border-0 checked:border-transparent checked:bg-transparent checked:after:opacity-100"
                            type="checkbox"
                            value=""
                            checked
                          />
                          <label className="mb-2 ml-1 font-normal cursor-pointer text-sm text-slate-700">
                            {' '}
                            I agree the{' '}
                            <span className="font-bold text-slate-700">
                              Terms and Conditions
                            </span>{' '}
                          </label>
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            onClick={registerBuyer}
                            className="inline-block w-full px-5 py-2.5 mt-6 mb-2 font-bold text-center text-white align-middle transition-all bg-transparent border-0 rounded-lg cursor-pointer active:opacity-85 hover:-translate-y-px hover:shadow-xs leading-normal text-sm ease-in tracking-tight-rem shadow-md bg-150 bg-x-25 bg-gradient-to-tl from-zinc-800 to-zinc-700 hover:border-slate-700 hover:bg-slate-700 hover:text-white"
                          >
                            Sign up
                          </button>
                        </div>
                        <p className="mt-4 mb-0 leading-normal text-sm">
                          Already have an account?{' '}
                          <Link
                            to="/buyerLogin"
                            className="font-bold text-slate-700"
                          >
                            Sign in
                          </Link>
                        </p>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="absolute top-0 right-0 flex-col justify-center hidden w-6/12 h-full max-w-full px-3 pr-0 my-auto text-center flex-0 lg:flex">
                  <div className="relative flex flex-col justify-center h-full  px-24 m-4 overflow-hidden  rounded-xl buyerBg">
                    <span className="absolute top-0 left-0 w-full h-full bg-center bg-cover bg-gradient-to-tl from-blue-500 to-violet-500 opacity-30"></span>
                    <h4 className="z-20 mt-12  font-bold text-white">
                      Welcome to Mescrow
                    </h4>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <footer className="py-12">
        <div className="  mt-5 pb-4 ">
          <div className="md:flex justify-center items-center ">
            <img
              src="/images/logo.png"
              className="w-24 mx-auto md:mx-0"
              alt="Logo"
            />
            <p className="text-md pl-5 md:pl-1   text-gray-800 dark:text-white hover:text-gray-700 dark:hover:text-gray-300">
              {' '}
              &copy; {year} Powered by{' '}
              <span className="font-bold"> Msimbo DevOps</span>
            </p>
            {/* <img src="/images/msimbo.PNG" className="w-16" alt="Logo" /> */}
          </div>
        </div>
      </footer>
    </>
  );
}

export default BuyerRegistration;
