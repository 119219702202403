import { Link } from 'react-router-dom';
import { useToggle } from '../provider/context';

export default function TopNavigation() {
  const userName = localStorage.getItem('usermescrowName');
  const userRole = localStorage.getItem('usermescrowRole');
  const { toggle } = useToggle();
  return (
    <>
      <header className="bg-white h-20 items-center relative w-full z-10">
        <div className="flex flex-col h-full justify-center mx-auto px-3 relative">
          <div className="flex items-center pl-1 relative w-full sm:ml-0 sm:pr-2 lg:max-w-68">
            <div className="flex left-0 relative w-3/4">
              <div className="flex group h-full items-center relative w-12">
                <button
                  type="button"
                  aria-expanded="false"
                  aria-label="Toggle sidenav"
                  onClick={toggle}
                  className="text-4xl text-gray-700 focus:outline-none lg:hidden"
                >
                  &#8801;
                </button>
              </div>
            </div>

            <h1 className=" text-xl font-bold text-white  lg:text-5xl">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-cyan-500 to-purple-500">
                Mescrow
              </span>{' '}
            </h1>

            <div className="flex  items-center justify-between w-full px-4 py-1 mx-auto flex-wrap-inherit">
              <div className="flex items-center mt-2 grow sm:mt-0 sm:mr-6 md:mr-0 lg:flex lg:basis-auto">
                <div className="flex items-center md:ml-auto md:pr-4"></div>
                <ul className="flex flex-row justify-end pl-0 mb-0 list-none md-max:w-full">
                  <li className="flex items-center">
                    {userRole === 'admin' ? (
                      <Link
                        to="/adminProfile"
                        className="block px-0 py-2 text-sm font-semibold text-white transition-all ease-nav-brand"
                      >
                        <div className="flex items-center">
                          <img
                            src="/images/avatar8.png"
                            alt="profile"
                            className="w-8"
                          />
                          <span className="hidden sm:inline text-black">
                            {userName}
                          </span>
                        </div>
                      </Link>
                    ) : userRole === 'seller' ? (
                      <Link
                        to="/venProfile"
                        className="block px-0 py-2 text-sm font-semibold text-white transition-all ease-nav-brand"
                      >
                        <div className="flex items-center">
                          <img
                            src="/images/avatar8.png"
                            alt="profile"
                            className="w-8"
                          />
                          <span className="hidden sm:inline text-black">
                            {userName}
                          </span>
                        </div>
                      </Link>
                    ) : userRole === 'buyer' ? (
                      <Link
                        to="/buyProfile"
                        className="block px-0 py-2 text-sm font-semibold text-white transition-all ease-nav-brand"
                      >
                        <div className="flex items-center">
                          <img
                            src="/images/avatar8.png"
                            alt="profile"
                            className="w-8"
                          />
                          <span className="hidden sm:inline text-black">
                            {userName}
                          </span>
                        </div>
                      </Link>
                    ) : userRole === 'rider' ? (
                      <Link
                        to="/ridProfile"
                        className="block px-0 py-2 text-sm font-semibold text-white transition-all ease-nav-brand"
                      >
                        <div className="flex items-center">
                          <img
                            src="/images/avatar8.png"
                            alt="profile"
                            className="w-8"
                          />
                          <span className="hidden sm:inline text-black">
                            {userName}
                          </span>
                        </div>
                      </Link>
                    ) : (
                      ' Loading ... '
                    )}
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </header>

      <div className="absolute w-full  h-96 bgTop"></div>
    </>
  );
}
