import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import DashboardLayout from './dashboard/layout';
import Register from './Authentication/vendor/Register';
import { useState } from 'react';
import { authContext } from './Context/AuthContext';
import Home from './pages/Home';
import BuyerRegistration from './Authentication/buyer/BuyerRegistration';
import BuyerLogin from './Authentication/buyer/BuyerLogin';
import RiderLogin from './Authentication/rider/RiderLogin';
import Login from './Authentication/vendor/Login';
import Protected from './ProtectedRoutes/Protected';
import VenDashboard from './pages/seller/VenDashboard';
import Page404 from './pages/Page404';
import Unauthorized from './pages/Unauthorized';
import VenProfile from './pages/seller/VenProfile';
import VenTransaction from './pages/seller/VenTransaction';
import VenRiders from './pages/seller/VenRiders';
import VenAddRider from './pages/seller/VenAddRider';
import VenViewRider from './pages/seller/VenViewRider';
import VenAddDelivery from './pages/seller/VenAddDelivery';
import VenDeliveries from './pages/seller/VenDeliveries';
import VenDisputes from './pages/seller/VenDisputes';
import BuyDashboard from './pages/buyer/BuyDashboard';
import BuyProfile from './pages/buyer/BuyProfile';
import BuyTransactions from './pages/buyer/BuyTransactions';
import BuyDisputes from './pages/buyer/BuyDisputes';
import BuyDeliveries from './pages/buyer/BuyDeliveries';
import RidDashboard from './pages/rider/RidDashboard';
import RidDeliveries from './pages/rider/RidDeliveries';
import RidDisputes from './pages/rider/RidDisputes';
import RidProfile from './pages/rider/RidProfile';
import VenViewProduct from './pages/seller/VenViewProduct';
import BuyViewProduct from './pages/buyer/BuyViewProduct';
import VenViewTransaction from './pages/seller/VenViewTransaction';
import BuyViewTransaction from './pages/buyer/BuyViewTransaction';
import BuyAddDispute from './pages/buyer/BuyAddDispute';
import RidViewProduct from './pages/rider/RidViewProduct';

function App() {
  const [logged, setLogged] = useState(false);
  const [userName, setUserName] = useState(undefined);
  const [userMobile, setUserMobile] = useState(undefined);
  const [userEmail, setUserEmail] = useState(undefined);
  const [userRole, setUserRole] = useState(undefined);

  return (
    <authContext.Provider
      value={{
        logged,
        setLogged,
        userName,
        setUserName,
        userMobile,
        setUserMobile,
        userEmail,
        setUserEmail,
        userRole,
        setUserRole,
      }}
    >
      <Router>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/vendorRegister" element={<Register />} />
          <Route path="/vendorLogin" element={<Login />} />
          <Route path="/buyerRegister" element={<BuyerRegistration />} />
          <Route path="/buyerLogin" element={<BuyerLogin />} />
          <Route path="/riderLogin" element={<RiderLogin />} />
        </Routes>
        <DashboardLayout>
          <Routes>
            <Route element={<Protected roles="seller" />}>
              <Route path="/venDashboard" element={<VenDashboard />} />
              <Route path="/venProfile" element={<VenProfile />} />
              <Route path="/venTransaction" element={<VenTransaction />} />
              <Route path="/venRiders" element={<VenRiders />} />
              <Route path="/venAddRider" element={<VenAddRider />} />
              <Route
                path="/venViewRider/:mobile_no"
                element={<VenViewRider />}
              />
              <Route path="/venDeliveries" element={<VenDeliveries />} />
              <Route path="/venAddDelivery" element={<VenAddDelivery />} />
              <Route path="/venDisputes" element={<VenDisputes />} />
              <Route
                path="/venViewProduct/:order_id"
                element={<VenViewProduct />}
              />
              <Route
                path="/venViewTransaction/:order_id"
                element={<VenViewTransaction />}
              />
            </Route>

            <Route element={<Protected roles="buyer" />}>
              <Route path="/buyDashboard" element={<BuyDashboard />} />
              <Route path="/buyProfile" element={<BuyProfile />} />
              <Route path="/buyTransaction" element={<BuyTransactions />} />
              <Route path="/buyDisputes" element={<BuyDisputes />} />
              <Route path="/buyDeliveries" element={<BuyDeliveries />} />
              <Route
                path="/buyViewProduct/:order_id"
                element={<BuyViewProduct />}
              />
              <Route
                path="/buyViewTransaction/:order_id"
                element={<BuyViewTransaction />}
              />
              <Route
                path="/buyAddDispute/:order_id"
                element={<BuyAddDispute />}
              />
            </Route>
            <Route element={<Protected roles="rider" />}>
              <Route path="/ridDashboard" element={<RidDashboard />} />
              <Route path="/ridDeliveries" element={<RidDeliveries />} />
              <Route path="/ridDisputes" element={<RidDisputes />} />
              <Route path="/ridProfile" element={<RidProfile />} />
              <Route
                path="/ridViewProduct/:order_id"
                element={<RidViewProduct />}
              />
            </Route>
            <Route path="*" element={<Page404 />} />
            <Route path="/unauthorized" element={<Unauthorized />} />
          </Routes>
        </DashboardLayout>
      </Router>
    </authContext.Provider>
  );
}

export default App;
