import React from 'react';
import Footer from '../../dashboard/footer/Footer';

function RidDisputes() {
  return (
    <>
      <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
        <div class="w-full px-3 md:px-6 py-6 mx-auto">
          <div class="flex flex-wrap md:mt-64 -mx-3">
            <div class="flex-none w-full max-w-full px-3">
              <div class="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl  rounded-2xl bg-clip-border">
                <div class="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                  <h4 class=" font-semibold my-3">My Disputes</h4>
                </div>
                <div class="flex-auto px-0 pt-0 pb-2">
                  <div class="p-0 overflow-x-hidden">
                    <table className="table-sorter table-bordered w-full text-left text-gray-600 ">
                      <thead>
                        <tr className="bg-gray-100  ">
                          <th
                            data-sortable="false"
                            className="hidden lg:table-cell"
                          >
                            <input
                              id="check_all"
                              type="checkbox"
                              className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded  "
                            />
                          </th>
                          <th className="hidden lg:table-cell">Seller</th>
                          <th className="hidden lg:table-cell">Order Id</th>
                          <th className="hidden lg:table-cell">Buyer</th>
                          <th className="hidden lg:table-cell">Product</th>
                          <th className="hidden lg:table-cell">Amount</th>
                          <th className="hidden lg:table-cell">Reasons</th>
                          <th className="hidden lg:table-cell">Status</th>
                          <th className="hidden lg:table-cell">Rider</th>
                          <th className="hidden lg:table-cell">Date</th>
                          <th className="hidden lg:table-cell">Location</th>
                        </tr>
                      </thead>
                      <tbody></tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}

export default RidDisputes;
