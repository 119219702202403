import { useContext } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { authContext } from '../../Context/AuthContext';
import Swal from 'sweetalert2';
import venData from '../../pages/seller/venData';
import buyData from '../../pages/buyer/buyData';
import ridData from '../../pages/rider/ridData';

const style = {
  inactive: `text-gray-700`,
  active: `font-bold text-white bg-blue-400`,
  link: `flex items-center  rounded-md justify-start my-2 p-4  w-full hover:text-white hover:bg-blue-400`,
};

export default function SidenavItems() {
  const userRole = localStorage.getItem('usermescrowRole');

  const { setLogged } = useContext(authContext);
  const { setUserEmail } = useContext(authContext);
  const { setUserRole } = useContext(authContext);
  const { setUserMobile } = useContext(authContext);
  const { setUserName } = useContext(authContext);
  const navigate = useNavigate();
  // console.log(logged);
  const handleLogout = () => {
    localStorage.removeItem('usermescrow-Token');
    localStorage.removeItem('usermescrowMobile');
    localStorage.removeItem('userEmail');
    localStorage.removeItem('usermescrowName');
    localStorage.removeItem('usermescrowRole');
    setLogged(false);
    setUserEmail(undefined);
    setUserRole(undefined);
    setUserMobile(undefined);
    setUserName(undefined);
    navigate('/');
    window.location.reload();
    Swal.fire({
      title: 'Logout Successfully!',
      text: 'thankyou for your time',
      icon: 'success',
      confirmButtonText: 'Ok',
    });
  };
  const { pathname } = useLocation();

  return (
    <ul className="md:pl-6">
      {userRole === 'seller' ? (
        venData.map((item) => (
          <li>
            <Link to={item.link} key={item.title}>
              <span
                className={`${style.link} 
                         ${
                           item.link === pathname
                             ? style.active
                             : style.inactive
                         }`}
              >
                <span>{item.icon}</span>
                <span className="mx-4">{item.title}</span>
              </span>
            </Link>
          </li>
        ))
      ) : userRole === 'buyer' ? (
        buyData.map((item) => (
          <li>
            <Link to={item.link} key={item.title}>
              <span
                className={`${style.link} 
                         ${
                           item.link === pathname
                             ? style.active
                             : style.inactive
                         }`}
              >
                <span>{item.icon}</span>
                <span className="mx-4">{item.title}</span>
              </span>
            </Link>
          </li>
        ))
      ) : userRole === 'rider' ? (
        ridData.map((item) => (
          <li>
            <Link to={item.link} key={item.title}>
              <span
                className={`${style.link} 
                         ${
                           item.link === pathname
                             ? style.active
                             : style.inactive
                         }`}
              >
                <span>{item.icon}</span>
                <span className="mx-4">{item.title}</span>
              </span>
            </Link>
          </li>
        ))
      ) : userRole === 'admin' ? (
        <Link to="/">
          <span className="ml-1 duration-300 opacity-100 pointer-events-none ease">
            Admin
          </span>
        </Link>
      ) : (
        ' Loading ... '
      )}

      <li>
        <button
          onClick={handleLogout}
          className="flex items-center  rounded-md justify-start my-2 p-4  w-full hover:text-white hover:bg-blue-400 font-bold text-white bg-indigo-400"
        >
          <span>
            <i className="fa-solid fa-right-from-bracket"></i>
          </span>
          <span className="mx-4">Logout</span>
        </button>
      </li>
    </ul>
  );
}
