import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Axios from 'axios';
import Footer from '../../dashboard/footer/Footer';

function BuyViewTransaction() {
  const { order_id } = useParams();
  const token = localStorage.getItem('usermescrow-Token');
  const [products, setProducts] = useState('');
  const [loading, setLoading] = useState(false);

  const [riderFare, setRiderFare] = useState(0);
  const [subTotal, setsubTotal] = useState(0);
  const [buyerNumber, setbuyerNumber] = useState('');
  const [action, setAction] = useState('');
  const [rider, setRider] = useState('');
  const [seller, setSeller] = useState('');
  const [date, setDate] = useState('');
  const [status, setStatus] = useState('');
  const [mpesa, setMpesa] = useState('');
  const [merchantRequestID, setMerchantRequestID] = useState('');
  const [tarrif, setTarrif] = useState('');

  useEffect(() => {
    Axios.post(
      'https://us-east4-mescrow.cloudfunctions.net/getalltransactions',
      { message: { access_token: token } },

      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => {
        // console.log('GEtting  Data ::', res.data.data);
        const myTransacts = Object.values(res.data.data);
        myTransacts.map((data) => {
          setLoading(true);
          if (data.Order_id == order_id) {
            setProducts(data.Product_name);
            setsubTotal(data.Amount);
            setRiderFare(data.transport_fees);

            setDate(data.Date.slice(0, 10));
            setSeller(data.seller_mobile_no);
            setRider(data.Rider_mobile_no);
            setbuyerNumber(data.buyer_mobile_no);
            setAction(data.Action);
            setStatus(data.status);
            setMpesa(data.receipt_no);
            setMerchantRequestID(data.MerchantRequestID);
            setTarrif(data.tarrif_charge);
          }
        });
      })
      .catch((err) => console.log(err));
  }, []);

  const myTransacts = Object.values(products);
  const dataProducts = myTransacts.map((data, index) => {
    return (
      <>
        <div class="grid grid-cols-3 sm:grid-cols-5 gap-2" key={index}>
          <div class="col-span-full sm:col-span-2">
            <h5 class="sm:hidden text-xs font-medium text-gray-600 uppercase">
              Item
            </h5>
            <p class="font-medium text-gray-800 dark:text-gray-200">
              {data.name}
            </p>
          </div>

          <div>
            <h5 class="sm:hidden text-xs font-medium text-gray-600 ">
              Initial Amount
            </h5>
            <p class="text-gray-800 dark:text-gray-200">
              {' '}
              {data.initialAmount}
            </p>
          </div>
          <div>
            <h5 class="sm:hidden text-xs font-medium text-gray-600 ">Qty</h5>
            <p class="text-gray-800 dark:text-gray-200"> {data.quantity}</p>
          </div>
          <div>
            <h5 class="sm:hidden text-xs font-medium text-gray-600 ">
              Total Amount
            </h5>
            <p class="sm:text-right text-gray-800 dark:text-gray-200">
              {data.totalAmount}
            </p>
          </div>
        </div>

        <div
          class="sm:hidden border-b border-gray-200 dark:border-gray-700"
          key={data.name}
        ></div>
      </>
    );
  });

  return (
    <>
      <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
        <div class="sm:w-11/12 md:mt-64 lg:w-3/4 mx-auto">
          <div class="flex flex-col p-4 sm:p-10 bg-white shadow-md rounded-xl dark:bg-gray-800">
            <h2 class="text-md md:text-2xl font-mono font-semibold mb-5 text-gray-800 dark:text-gray-200">
              <span className=" text-cyan-900">View Transactions Detail</span>
            </h2>
            <div class="mt-8 grid sm:grid-cols-2 gap-3">
              <div>
                <h2 class="text-md md:text-xl font-semibold mb-5 text-gray-800 dark:text-gray-200">
                  <span className="font-bold">#Order_ID:</span> {order_id}
                </h2>

                <h2 class="text-md md:text-xl font-semibold mb-5 text-gray-800 dark:text-gray-200">
                  {mpesa ? (
                    <span>
                      <span className="font-bold">Mpesa Transaction: </span>
                      {mpesa}
                    </span>
                  ) : (
                    <span>
                      <span className="font-bold">MerchantRequestID: </span>
                      {merchantRequestID}
                    </span>
                  )}
                </h2>

                <div class="mt-2 not-italic text-gray-500">
                  <span className="my-2">
                    <span className="font-bold">From (Seller): </span> +{seller}
                    ,
                  </span>
                  <br />
                  <span className="my-2">
                    <span className="font-bold"> To (Buyer): </span> +
                    {buyerNumber}
                  </span>
                  ,
                  <br />
                  <span className="my-2">
                    <span className="font-bold">Delivery Guy (Rider):</span>+
                    {rider},
                  </span>
                  <br />
                </div>
              </div>

              <div class="sm:text-right space-y-2">
                <div class="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                  <dl class="grid sm:grid-cols-5 gap-x-3">
                    <dt class="col-span-3 font-semibold text-gray-800 dark:text-gray-200">
                      Published date:
                    </dt>
                    <dd class="col-span-2 text-gray-500">{date}</dd>
                  </dl>
                  <dl class="grid sm:grid-cols-5 gap-x-3">
                    <dt class="col-span-3 font-semibold text-gray-800 dark:text-gray-200">
                      Action:
                    </dt>
                    <dd class="col-span-2 text-gray-500">{action}</dd>
                  </dl>

                  <dl class="grid sm:grid-cols-5 gap-x-3">
                    <dt class="col-span-3 font-semibold text-gray-800 dark:text-gray-200">
                      Status:
                    </dt>
                    <dd class="col-span-2 text-gray-500">
                      {' '}
                      {
                        <span className="text-sm px-2 py-1 font-semibold leading-tight text-white bg-red-600 rounded-full">
                          {status}
                        </span>
                      }
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <div class="mt-6">
              <div class="border border-gray-200 p-4 rounded-lg space-y-4 dark:border-gray-700">
                <div class="hidden sm:grid sm:grid-cols-5">
                  <div class="sm:col-span-2 text-xs font-medium text-gray-500 uppercase">
                    Item
                  </div>
                  <div class="text-left text-xs font-medium text-gray-500 uppercase">
                    Initial Amount
                  </div>
                  <div class="text-left text-xs font-medium text-gray-500 uppercase">
                    Qty
                  </div>
                  <div class="text-right text-xs font-medium text-gray-500 uppercase">
                    Total Amount
                  </div>
                </div>

                <div class="hidden sm:block border-b border-gray-200 dark:border-gray-700"></div>
                {loading ? (
                  dataProducts
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="50"
                    height="50"
                    fill="currentColor"
                    className="bi bi-arrow-repeat animate-spin"
                    viewBox="0 0 16 16"
                  >
                    <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                    <path d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                  </svg>
                )}
              </div>
            </div>

            <div class="mt-8 flex sm:justify-end">
              <div class="w-full max-w-2xl sm:text-right space-y-2">
                <div class="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                  <dl class="grid sm:grid-cols-5 gap-x-3">
                    <dt class="col-span-3 font-semibold text-gray-800 dark:text-gray-200">
                      Rider's Fare:
                    </dt>
                    <dd class="col-span-2 text-gray-500">{riderFare} KES</dd>
                  </dl>
                  <dl class="grid sm:grid-cols-5 gap-x-3">
                    <dt class="col-span-3 font-semibold text-gray-800 dark:text-gray-200">
                      Tarrif Charges:
                    </dt>
                    <dd class="col-span-2 text-gray-500">{tarrif} KES</dd>
                  </dl>
                  <dl class="grid sm:grid-cols-5 gap-x-3">
                    <dt class="col-span-3 font-semibold text-gray-800 dark:text-gray-200">
                      Subtotal:
                    </dt>
                    <dd class="col-span-2 text-gray-500">{subTotal} KES</dd>
                  </dl>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </main>
    </>
  );
}

export default BuyViewTransaction;
