import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import Home from '../pages/Home';

const Protected = (props) => {
  const userMobile = localStorage.getItem('usermescrowMobile');

  if (userMobile) {
    const user = localStorage.getItem('usermescrowRole');

    // if (props.roles === user) {
    //   <Outlet />;
    // } else {
    //   <Navigate to="/unauthorized" replace />;
    // }

    return props.roles === user ? (
      <Outlet />
    ) : (
      <Navigate to="/unauthorized" replace />
    );
  } else {
    return <Home />;
  }
};

export default Protected;
