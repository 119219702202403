const ridData = [
  {
    title: 'My Dashboard',
    icon: <i className="text-blue-600 fa-solid fa-home"></i>,
    link: '/ridDashboard',
  },

  {
    title: 'Deliveries',
    icon: <i className="text-cyan-500 fas fa-shipping-fast"></i>,
    link: '/ridDeliveries',
  },
  {
    title: 'Disputes',
    icon: <i className="text-red-600 fas fa-user-shield"></i>,
    link: '/ridDisputes',
  },
  {
    title: 'Profile',
    icon: <i className="text-slate-700 fa-solid fa-user"></i>,
    link: '/ridProfile',
  },
];

export default ridData;
