import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import Footer from '../../dashboard/footer/Footer';
import Axios from 'axios';

function VenViewRider() {
  const { mobile_no } = useParams();
  const token = localStorage.getItem('usermescrow-Token');
  const userMobile = localStorage.getItem('usermescrowMobile');
  const [deliveries, setDeliveries] = useState('');
  const [loading, setLoading] = useState(false);

  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [location, setLocation] = useState('');

  useEffect(() => {
    Axios.post(
      'https://us-central1-mescrow.cloudfunctions.net/getallriders',
      {
        message: {
          access_token: token,
          user_type: 'rider',
          Vendor_mobile_no: userMobile,
        },
      },
      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => {
        // console.log('GEtting Riders Data ::', res.data.data);
        const myRiders = Object.values(res.data.data);
        myRiders.map((data) => {
          if (data.Mobile_no == mobile_no) {
            setFirstName(data.Firstname);
            setLastName(data.Lastname);
            setEmail(data.Email_address);
            setMobile(data.Mobile_no);
            setLocation(data.Current_location);
          }
        });
      })
      .catch((err) => console.log(err));

    Axios.post(
      'https://us-east4-mescrow.cloudfunctions.net/getalldeliveries',
      { message: { access_token: token } },

      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => {
        // console.log('GEtting  Data ::', res.data.data);
        setLoading(true);

        setDeliveries(res.data.data);
      })
      .catch((err) => console.log(err));
  }, []);

  const myDeliveries = Object.values(deliveries);
  const dataDeliveries = myDeliveries.map((data, index) => {
    if (data.Rider_mobile_no == mobile_no)
      return (
        <tr
          key={index}
          className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
        >
          <td className="border-grey-light border hover:bg-gray-100 p-3">
            <input
              type="checkbox"
              className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded  "
            />
          </td>

          <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
            {data.Order_id}
          </td>
          <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
            {' '}
            {data.seller_mobile_no}
          </td>

          <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
            {data.status ? (
              <span className="text-sm px-2 py-1 font-semibold leading-tight text-white bg-green-600 rounded-full">
                {data.status}
              </span>
            ) : (
              <span className="text-sm px-2 py-1 font-semibold leading-tight text-white bg-red-600 rounded-full">
                {data.Status}
              </span>
            )}
          </td>
          <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
            {data.Date}
          </td>
        </tr>
      );
  });

  return (
    <>
      <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
        <div class="w-full px-3 md:px-6 py-6 mx-auto">
          <div className="mx-auto md:p-2">
            <div className="flex-shrink md:mt-36 max-w-full md:px-4 w-full">
              <div className="bg-white  rounded-lg overflow-hidden pb-8">
                <div className="h-40 overflow-hidden relative">
                  <img
                    src="/images/bg-profile.jpg"
                    className="w-full"
                    alt="background"
                  />
                </div>
                <div className="text-center ">
                  <div className="relative flex justify-center -mt-10">
                    <img
                      src="/images/avatar8.png"
                      className="rounded-full w-24  bg-gray-200 border-solid border-white border-2 -mt-3"
                      alt="profile"
                    />
                  </div>

                  <div className="md:px-6 py-3 text-xl border-b  font-bold ">
                    {firstname} Profile
                  </div>
                </div>
              </div>
            </div>

            <div className="flex mt-4 flex-wrap flex-row">
              <div className="flex-shrink mx-auto max-w-full md:px-4 w-full lg:w-2/3 mb-6">
                <div class="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl  rounded-2xl bg-clip-border">
                  <div class="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                    <h4 class=" font-semibold my-3">{firstname} Deliveries</h4>
                  </div>
                  <div class="flex-auto px-0 pt-0 pb-2">
                    <div class="p-0 overflow-x-hidden">
                      <table className="table-sorter table-bordered w-full text-left text-gray-600 ">
                        <thead>
                          <tr className="bg-gray-100  ">
                            <th
                              data-sortable="false"
                              className="hidden lg:table-cell"
                            >
                              <input
                                id="check_all"
                                type="checkbox"
                                className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded  "
                              />
                            </th>
                            <th className="hidden lg:table-cell">Order Id</th>
                            <th className="hidden lg:table-cell">Seller</th>
                            <th className="hidden lg:table-cell">Status</th>
                            <th className="hidden lg:table-cell">Date</th>
                          </tr>
                        </thead>
                        <tbody>
                          {loading ? (
                            dataDeliveries
                          ) : (
                            <tr className="flex justify-center  items-center h-40 w-full">
                              <td className="w-full mx-auto ">
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="50"
                                  height="50"
                                  fill="currentColor"
                                  className="bi bi-arrow-repeat animate-spin"
                                  viewBox="0 0 16 16"
                                >
                                  <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                  <path d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                                </svg>
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex-shrink max-w-full md:px-4 w-full lg:w-1/3 mb-6">
                <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                  <img
                    class="w-full rounded-t-2xl"
                    src="/images/bg-profile.jpg"
                    alt="profile"
                  />
                  <div class="flex flex-wrap justify-center -mx-3">
                    <div class="w-4/12 max-w-full px-3 flex-0 ">
                      <div class="mb-6 -mt-6 lg:mb-0 lg:-mt-16">
                        <img
                          class="h-auto max-w-full border-2 border-white border-solid rounded-circle"
                          src="/images/avatar8.png"
                          alt="profile"
                        />
                      </div>
                    </div>
                  </div>

                  <h1 class="ml-2 text-gray-800  font-akaya my-3 text-xl">
                    Rider Details
                  </h1>

                  <div class="content-header flex justify-between items-center my-3 md:px-4 font-andika">
                    <h1 class="font-bold text-gray-800 ">
                      <span class="px-3 py-2 inline-block  md:mr-4 text-blue-500 bg-blue-100 rounded-full ">
                        <i class="fas fa-user"></i>
                      </span>{' '}
                      &nbsp;Name
                    </h1>
                    <span class="">
                      {firstname} {lastname}
                    </span>
                  </div>

                  <div class="content-header flex justify-between items-center my-3 md:px-4 font-andika">
                    <h1 class="font-bold text-gray-800 ">
                      <span class="px-3 py-2 inline-block  md:mr-4 text-blue-500 bg-blue-100 rounded-full  ">
                        <i class="fas fa-phone"></i>
                      </span>{' '}
                      &nbsp;Phone
                    </h1>
                    <span class="">+{mobile}</span>
                  </div>

                  <div class="content-header flex justify-between items-center my-3 md:px-4 font-andika">
                    <h1 class="font-bold text-gray-800 ">
                      <span class="px-3 py-2 inline-block  md:mr-4 text-blue-500 bg-blue-100 rounded-full ">
                        <i class="fas fa-envelope-open"></i>
                      </span>{' '}
                      &nbsp;Email
                    </h1>
                    <span class="">{email}</span>
                  </div>
                  <div class="content-header flex justify-between items-center my-3 md:px-4 font-andika">
                    <h1 class="font-bold text-gray-800 ">
                      <span class="px-3 py-2 inline-block  md:mr-4 text-blue-500 bg-blue-100 rounded-full ">
                        <i class="fas fa-envelope-open"></i>
                      </span>{' '}
                      &nbsp;Location
                    </h1>
                    <span class="">{location}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}

export default VenViewRider;
