import React, { useState } from 'react';
import Axios from 'axios';
import Swal from 'sweetalert2';
import { useNavigate } from 'react-router-dom';

function VenAddRider() {
  const userName = localStorage.getItem('usermescrowName');
  const userMobile = localStorage.getItem('usermescrowMobile');
  const userRole = localStorage.getItem('usermescrowRole');
  const token = localStorage.getItem('usermescrow-Token');

  const [firstname, setFirstName] = useState('');
  const [lastname, setLastName] = useState('');
  const [mobile_no, setMobile] = useState('');
  const [email, setEmail] = useState('');
  const [vendor] = useState(userMobile);
  const [location, setLocation] = useState('');
  const [password, setPassword] = useState('');
  const [cpassword, setCPassword] = useState('');

  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const registerRider = (e) => {
    e.preventDefault();
    setLoading(true);

    if (
      firstname &&
      lastname &&
      mobile_no &&
      email &&
      vendor &&
      location &&
      password &&
      cpassword
    ) {
      Axios.post(
        'https://us-east4-mescrow.cloudfunctions.net/rider_registration',
        {
          message: {
            access_token: token,
            Firstname: firstname,
            Lastname: lastname,
            Vendor_mobile_no: vendor,
            Mobile_no: mobile_no,
            Email_address: email,
            Current_location: location,
            Password: password,
            confirm_Password: cpassword,
          },
        },
      )
        .then((res) => {
          console.log('Posting Data From::', res.data);
          setLoading(false);

          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Rider Registered Successfully',
            showConfirmButton: false,
            timer: 1500,
          });

          navigate('/venRiders');
        })
        .catch((err) => {
          console.log(err.message);
          setLoading(false);
        });
    } else {
      Swal.fire({
        title: 'All Fields Are Required!',
        text: 'Please Fill All Fields',
        icon: 'error',
        confirmButtonText: 'Ok',
      });
      setLoading(false);
    }
  };

  return (
    <>
      <div
        className={`${
          !loading && 'hidden'
        } w-full h-full fixed  top-0 left-0 bg-white opacity-75 z-50`}
      >
        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
          <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
      </div>
      <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
        <div class="w-full px-3 md:px-6 py-6 mx-auto">
          <div class="flex flex-wrap md:mt-60 -mx-3">
            <div class="w-full max-w-full px-3 shrink-0 md:w-8/12 md:flex-0">
              <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl   rounded-2xl bg-clip-border">
                <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                  <div class="">
                    <h4 class=" font-semibold my-3">Create New Rider</h4>
                  </div>
                </div>
                <div class="flex-auto p-6">
                  <div class="flex flex-wrap -mx-3">
                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <div class="mb-4">
                        <label
                          for="username"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                        >
                          Firstname
                        </label>
                        <input
                          type="text"
                          name="fname"
                          class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          value={firstname}
                          onChange={(e) => {
                            setFirstName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <div class="mb-4">
                        <label
                          for="email"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                        >
                          Lastname
                        </label>
                        <input
                          type="text"
                          name="lname"
                          class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          value={lastname}
                          onChange={(e) => {
                            setLastName(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <div class="mb-4">
                        <label
                          for="username"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                        >
                          Vendor Mobile
                        </label>
                        <input
                          disabled
                          type="tel"
                          name="vendor"
                          class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          value={vendor}
                        />
                      </div>
                    </div>

                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <div class="mb-4">
                        <label
                          for="username"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                        >
                          Mobile no
                        </label>
                        <input
                          type="tel"
                          name="number"
                          class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          value={mobile_no}
                          onChange={(e) => {
                            setMobile(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <div class="mb-4">
                        <label
                          for="email"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                        >
                          Email address
                        </label>
                        <input
                          type="email"
                          name="email"
                          class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          value={email}
                          onChange={(e) => {
                            setEmail(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <div class="mb-4">
                        <label
                          for="location"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                        >
                          Current location
                        </label>
                        <input
                          type="text"
                          name="location"
                          class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          value={location}
                          onChange={(e) => {
                            setLocation(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <div class="mb-4">
                        <label
                          for="password"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                        >
                          Password
                        </label>
                        <input
                          type="password"
                          name="password"
                          class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          value={password}
                          onChange={(e) => {
                            setPassword(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                    <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                      <div class="mb-4">
                        <label
                          for="email"
                          class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 "
                        >
                          Confirm Password
                        </label>
                        <input
                          type="password"
                          name="cpassword"
                          class="focus:shadow-primary-outline   text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          value={cpassword}
                          onChange={(e) => {
                            setCPassword(e.target.value);
                          }}
                        />
                      </div>
                    </div>
                  </div>

                  <button
                    type="button"
                    class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                    onClick={registerRider}
                  >
                    Add Rider
                  </button>
                </div>
              </div>
            </div>
            <div class="w-full max-w-full px-3 mt-6 shrink-0 md:w-4/12 md:flex-0 md:mt-0">
              <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl   rounded-2xl bg-clip-border">
                <img
                  class="w-full rounded-t-2xl"
                  src="/images/bg-profile.jpg"
                  alt="profile cover"
                />
                <div class="flex flex-wrap justify-center -mx-3">
                  <div class="w-4/12 max-w-full px-3 flex-0 ">
                    <div class="mb-6 -mt-6 lg:mb-0 lg:-mt-16">
                      <img
                        class="h-auto max-w-full border-2 border-white border-solid rounded-circle"
                        src="/images/avatar8.png"
                        alt="profile "
                      />
                    </div>
                  </div>
                </div>

                <div class="flex-auto p-6 pt-0">
                  <div class="mt-6 text-center">
                    <h5 class=" ">{userName}</h5>

                    <div class="mt-6 mb-2 font-semibold leading-relaxed text-base uppercase  text-slate-700">
                      <i class="mr-2  ni ni-briefcase-24"></i>
                      {userRole}
                    </div>
                    <div class="">
                      <i class="mr-2  fas fa-phone"></i>
                      {userMobile}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
}

export default VenAddRider;
