const venData = [
  {
    title: 'My Dashboard',
    icon: <i className="text-blue-600 fa-solid fa-home"></i>,
    link: '/venDashboard',
  },
  {
    title: 'Transactions',
    icon: <i className="text-emerald-500 fas fa-piggy-bank"></i>,
    link: '/venTransaction',
  },
  {
    title: 'Deliveries',
    icon: <i className="text-cyan-500 fas fa-shipping-fast"></i>,
    link: '/venDeliveries',
  },
  {
    title: 'Disputes',
    icon: <i className="text-red-600 fas fa-user-shield"></i>,
    link: '/venDisputes',
  },
  {
    title: 'My Riders',
    icon: <i className="text-yellow-600 fas fa-user-shield"></i>,
    link: '/venRiders',
  },
  {
    title: 'Profile',
    icon: <i className="text-slate-700 fa-solid fa-user"></i>,
    link: '/venProfile',
  },
];

export default venData;
