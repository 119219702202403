import Overlay from './provider/overlay';
import TopNavigation from './topnavigation';
import SideNavigation from './sidenavigation';
import DashboardProvider from './provider/context';
import { useContext } from 'react';
import { authContext } from '../Context/AuthContext';

const style = {
  container: `h-screen overflow-hidden relative`,
  mainContainer: `bg-white flex flex-col h-screen pl-0 w-full lg:w-99`,
  main: `bg-gray-100 h-screen overflow-auto pb-36 pt-4 px-2 md:pb-8 md:px-4 lg:px-6 lg:rounded-tl-3xl`,
};

export default function DashboardLayout({ children }) {
  const { setLogged } = useContext(authContext);
  if (!localStorage.getItem('usermescrowMobile')) {
    return <></>;
  } else {
    if (setLogged) {
      return (
        <DashboardProvider>
          <div className={style.container}>
            <div className="flex items-start">
              <Overlay />
              <SideNavigation mobilePosition="right" />
              <div className={style.mainContainer}>
                <TopNavigation />
                <main className={style.main}>{children}</main>
              </div>
            </div>
          </div>
        </DashboardProvider>
      );
    }
  }
}
