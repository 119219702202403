import React, { useEffect, useState } from 'react';
import Footer from '../../dashboard/footer/Footer';
import Axios from 'axios';
import { Link } from 'react-router-dom';
import Swal from 'sweetalert2';

function BuyTransactions() {
  const token = localStorage.getItem('usermescrow-Token');
  const [transactions, setTransactions] = useState('');
  const [loading, setLoading] = useState(false);
  const userMobile = localStorage.getItem('usermescrowMobile');

  useEffect(() => {
    Axios.post(
      'https://us-east4-mescrow.cloudfunctions.net/getalltransactions',
      { message: { access_token: token } },

      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => {
        const myTransactions = Object.values(res.data.data);

        const transacts = myTransactions.filter(
          (transacts) => transacts.buyer_mobile_no === userMobile,
        );
        console.log('GEtting  Data ::', transacts);
        setLoading(true);

        setTransactions(transacts);
      })
      .catch((err) => console.log(err));
  }, []);

  const myTransactions = Object.values(transactions);
  const dataTransactions = myTransactions.map((data, index) => {
    return (
      <tr
        key={index}
        className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
      >
        <td className="border-grey-light border hover:bg-gray-100 p-3">
          <input
            type="checkbox"
            className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded  "
          />
        </td>

        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          {parseInt(data.Amount)}
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          {' '}
          {data.buyer_mobile_no}
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          {' '}
          {data.receipt_no ? data.receipt_no : data.MerchantRequestID}
        </td>

        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          {data.status ? (
            <span className="text-sm px-2 py-1 font-semibold leading-tight text-white bg-green-600 rounded-full">
              {data.status}
            </span>
          ) : (
            <span className="text-sm px-2 py-1 font-semibold leading-tight text-white bg-red-600 rounded-full">
              {data.Status}
            </span>
          )}
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          {data.Date}
        </td>

        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          <Link to={`/buyViewTransaction/${data.Order_id}`}>
            <button
              type="button"
              class="hidden my-2 px-8 py-2 font-bold leading-normal text-center text-white align-middle transition-all ease-in border-0 rounded-lg shadow-md cursor-pointer text-xs bg-cyan-500 lg:block tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
            >
              View{' '}
            </button>
          </Link>
          <button
            onClick={() => {
              Swal.fire({
                title: 'Release Payment To Seller ?',
                icon: 'question',
                text: `Are you sure you want to Release Payment to your Seller and Rider `,
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: `No`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  Axios.post(
                    'https://us-central1-mescrow.cloudfunctions.net/buyer_release_funds_re',
                    {
                      message: {
                        access_token: token,
                        MerchantRequestID: data.MerchantRequestID,
                        Order_id: data.Order_id,
                      },
                    },
                  )
                    .then((res) => {
                      console.log('Posting SHG Link Data From::', res.data);
                      Swal.fire(
                        'Payment Released Successfully!',
                        '',
                        'success',
                      );
                    })
                    .catch((err) => console.log(err));
                } else if (result.isDenied) {
                  Swal.fire('No Payment Released', '', 'info');
                }
              });
            }}
            type="button"
            class="hidden my-2 px-8 py-2 font-bold leading-normal text-center text-white align-middle transition-all ease-in border-0 rounded-lg shadow-md cursor-pointer text-xs bg-green-500 lg:block tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
          >
            Release payment{' '}
          </button>

          <button
            onClick={() => {
              Swal.fire({
                title: 'Activate Transaction ?',
                icon: 'question',
                text: `Are you sure you want to Activate your Transaction `,
                showDenyButton: true,
                showCancelButton: true,
                confirmButtonText: 'Yes',
                denyButtonText: `No`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  Axios.post(
                    'https://us-central1-mescrow.cloudfunctions.net/reSTKpush_new',
                    {
                      message: {
                        access_token: token,
                        MerchantRequestID: data.MerchantRequestID,
                        Order_id: data.Order_id,
                      },
                    },
                  )
                    .then((res) => {
                      console.log('Posting SHG Link Data From::', res.data);
                      Swal.fire(
                        'Trasaction Activated Successfully!',
                        '',
                        'success',
                      );
                    })
                    .catch((err) => console.log(err));
                } else if (result.isDenied) {
                  Swal.fire('No changes made', '', 'info');
                }
              });
            }}
            type="button"
            class="hidden my-2 px-8 py-2 font-bold leading-normal text-center text-white align-middle transition-all ease-in border-0 rounded-lg shadow-md cursor-pointer text-xs bg-red-500 lg:block tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
          >
            Accept Payment{' '}
          </button>
        </td>
      </tr>
    );
  });

  return (
    <>
      <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
        <div class="w-full px-3 md:px-6 py-6 mx-auto">
          <div class="flex flex-wrap md:mt-64 -mx-3">
            <div class="flex-none w-full max-w-full px-3">
              <div class="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl  rounded-2xl bg-clip-border">
                <div class="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                  <h4 class=" font-semibold my-3">My Transactions</h4>
                </div>
                <div class="flex-auto px-0 pt-0 pb-2">
                  <div class="p-0 overflow-x-hidden">
                    <table className="table-sorter table-bordered w-full text-left text-gray-600 ">
                      <thead>
                        <tr className="bg-gray-100  ">
                          <th
                            data-sortable="false"
                            className="hidden lg:table-cell"
                          >
                            <input
                              id="check_all"
                              type="checkbox"
                              className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded  "
                            />
                          </th>
                          <th className="hidden lg:table-cell">Amount</th>
                          <th className="hidden lg:table-cell">Buyer</th>
                          <th className="hidden lg:table-cell">
                            Transaction Code
                          </th>
                          <th className="hidden lg:table-cell">Status</th>
                          <th className="hidden lg:table-cell">Date</th>
                          <th className="hidden lg:table-cell">Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          dataTransactions
                        ) : (
                          <tr className="flex justify-center  items-center h-40 w-full">
                            <td className="w-full mx-auto ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="50"
                                height="50"
                                fill="currentColor"
                                className="bi bi-arrow-repeat animate-spin"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                <path d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                              </svg>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}

export default BuyTransactions;
