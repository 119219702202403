import React from 'react';
import Footer from '../../dashboard/footer/Footer';

function BuyProfile() {
  const userName = localStorage.getItem('usermescrowName');
  const userMobile = localStorage.getItem('usermescrowMobile');
  const userEmail = localStorage.getItem('userEmail');

  return (
    <>
      <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
        <div class="w-full px-3 md:px-6 py-6 mx-auto">
          <div className="mx-auto md:p-2">
            <div className="flex-shrink md:mt-36 max-w-full md:px-4 w-full">
              <div className="bg-white  rounded-lg overflow-hidden pb-8">
                <div className="h-40 overflow-hidden relative">
                  <img
                    src="/images/bg-profile.jpg"
                    className="w-full"
                    alt="background"
                  />
                </div>
                <div className="text-center ">
                  <div className="relative flex justify-center -mt-10">
                    <img
                      src="/images/avatar8.png"
                      className="rounded-full w-24  bg-gray-200 border-solid border-white border-2 -mt-3"
                      alt="profile"
                    />
                  </div>

                  <div className="md:px-6 py-3 text-xl border-b  font-bold  font-andika">
                    Update Your Profile
                  </div>
                </div>
              </div>
            </div>

            <div className="flex mt-4 flex-wrap flex-row">
              <div className="flex-shrink mx-auto max-w-full md:px-4 w-full lg:w-2/3 mb-6">
                <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                  <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                    <div class="flex items-center">
                      <h4 class=" font-semibold my-3">Edit Profile</h4>
                      <button
                        type="button"
                        class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                      >
                        Save Changes
                      </button>
                    </div>
                  </div>
                  <div class="flex-auto p-6">
                    <div class="flex flex-wrap -mx-3">
                      <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                        <div class="mb-4">
                          <label class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 ">
                            Username
                          </label>
                          <input
                            type="text"
                            name="fname"
                            class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                            value={userName}
                          />
                        </div>
                      </div>

                      <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                        <div class="mb-4">
                          <label class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 ">
                            Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                            value={userEmail}
                          />
                        </div>
                      </div>

                      <div class="w-full max-w-full px-3 shrink-0 md:w-6/12 md:flex-0">
                        <div class="mb-4">
                          <label class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 ">
                            Password
                          </label>
                          <input
                            type="password"
                            name="password"
                            class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                          />
                        </div>
                      </div>
                    </div>
                    <hr class="h-px mx-0 my-4 bg-transparent border-0 opacity-25 bg-gradient-to-r from-transparent via-black/40 to-transparent  " />
                  </div>
                </div>
              </div>
              <div className="flex-shrink max-w-full md:px-4 w-full lg:w-1/3 mb-6">
                <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                  <img
                    class="w-full rounded-t-2xl"
                    src="/images/bg-profile.jpg"
                    alt="profile"
                  />
                  <div class="flex flex-wrap justify-center -mx-3">
                    <div class="w-4/12 max-w-full px-3 flex-0 ">
                      <div class="mb-6 -mt-6 lg:mb-0 lg:-mt-16">
                        <img
                          class="h-auto max-w-full border-2 border-white border-solid rounded-circle"
                          src="/images/avatar8.png"
                          alt="profile"
                        />
                      </div>
                    </div>
                  </div>

                  <h1 class="ml-2 text-gray-800  font-akaya my-3 text-xl">
                    Personal Details
                  </h1>

                  <div class="content-header flex justify-between items-center my-3 md:px-4 font-andika">
                    <h1 class="font-bold text-gray-800 ">
                      <span class="px-3 py-2 inline-block  md:mr-4 text-blue-500 bg-blue-100 rounded-full ">
                        <i class="fas fa-user"></i>
                      </span>{' '}
                      &nbsp;Name
                    </h1>
                    <span class="">{userName}</span>
                  </div>

                  <div class="content-header flex justify-between items-center my-3 md:px-4 font-andika">
                    <h1 class="font-bold text-gray-800 ">
                      <span class="px-3 py-2 inline-block  md:mr-4 text-blue-500 bg-blue-100 rounded-full  ">
                        <i class="fas fa-phone"></i>
                      </span>{' '}
                      &nbsp;Phone
                    </h1>
                    <span class="">+{userMobile}</span>
                  </div>

                  <div class="content-header flex justify-between items-center my-3 md:px-4 font-andika">
                    <h1 class="font-bold text-gray-800 ">
                      <span class="px-3 py-2 inline-block  md:mr-4 text-blue-500 bg-blue-100 rounded-full ">
                        <i class="fas fa-envelope-open"></i>
                      </span>{' '}
                      &nbsp;Email
                    </h1>
                    <span class="">{userEmail}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}

export default BuyProfile;
