import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import Axios from 'axios';
import Footer from '../../dashboard/footer/Footer';
import Swal from 'sweetalert2';

function BuyAddDispute() {
  const { order_id } = useParams();
  const token = localStorage.getItem('usermescrow-Token');
  const [products, setProducts] = useState('');
  const [loading, setLoading] = useState(false);
  const userName = localStorage.getItem('usermescrowName');

  const [buyerNumber, setbuyerNumber] = useState('');
  const [address, setAddress] = useState('');
  const [rider, setRider] = useState('');
  const [seller, setSeller] = useState('');
  const [date, setDate] = useState('');
  const [datey, setDatey] = useState('');
  const [timey, setTimey] = useState('');
  const [status, setStatus] = useState('');
  const [reason, setReason] = useState();
  const navigate = useNavigate();

  useEffect(() => {
    Axios.post(
      'https://us-east4-mescrow.cloudfunctions.net/getalldeliveries',
      { message: { access_token: token } },

      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => {
        console.log('GEtting  Data ::', res.data.data);
        const myDel = Object.values(res.data.data);
        myDel.map((data) => {
          if (data.Order_id == order_id) {
            setProducts(data.Product_name);
            setDatey(data.Duration.slice(0, 10));
            setTimey(data.Duration.slice(12));
            setDate(data.Date.slice(0, 10));
            setSeller(data.seller_mobile_no);
            setRider(data.Rider_mobile_no);
            setbuyerNumber(data.buyer_mobile_no);
            setAddress(data.Location);
            setStatus(data.status);
          }
        });
      })
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    if (reason) {
      setLoading(true);
      Axios.post('https://us-east4-mescrow.cloudfunctions.net/add_dispute', {
        message: {
          access_token: token,
          Buyer: userName,
          Order_id: order_id,
          Product_name: products,
          Rider_mobile_no: rider,
          seller_mobile_no: seller,
          buyer_mobile_no: buyerNumber,
          Reason: reason,
          Status: status,
          Date: date,
          Location: address,
        },
      })
        .then((res) => {
          console.log(res.data);
          setLoading(false);
          Swal.fire({
            position: 'center',
            icon: 'success',
            title: 'Dispute Added Successfully',
            showConfirmButton: false,
            timer: 1500,
          });
          navigate('/buyDisputes');
        })
        .catch((err) => {
          setLoading(false);
          console.log(err);
        });
    } else {
      Swal.fire({
        position: 'center',
        icon: 'error',
        title: 'Add a Reason for Dispute',
      });
    }
  };

  return (
    <>
      <div
        className={`${
          !loading && 'hidden'
        } w-full h-full fixed  top-0 left-0 bg-white opacity-75 z-50`}
      >
        <span className="text-green-500 opacity-75 top-1/2 my-0 mx-auto block relative w-0 h-0">
          <i className="fas fa-circle-notch fa-spin fa-5x"></i>
        </span>
      </div>
      <main class="relative  h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
        <div class=" sm:w-11/12 md:mt-64 lg:w-3/4  mx-auto">
          <div class="flex flex-col p-4 sm:p-10 bg-white shadow-md rounded-xl dark:bg-gray-800">
            <h2 class="text-md md:text-2xl font-mono font-semibold mb-5 text-gray-800 dark:text-gray-200">
              <span className=" text-cyan-900">
                {' '}
                Add Dispute For This Delivery
              </span>
            </h2>
            <div class="mt-8 grid sm:grid-cols-2 gap-3">
              <div>
                <h2 class="text-md md:text-xl font-semibold mb-5 text-gray-800 dark:text-gray-200">
                  <span className="font-bold">#Order_ID:</span> {order_id}
                </h2>

                <div class="mt-2 not-italic text-gray-500">
                  <span className="my-2">
                    <span className="font-bold">From (Seller): </span> +{seller}
                    ,
                  </span>
                  <br />
                  <span className="my-2">
                    <span className="font-bold"> To (Buyer): </span> +
                    {buyerNumber}
                  </span>
                  ,
                  <br />
                  <span className="my-2">
                    <span className="font-bold">Delivery Guy (Rider):</span>+
                    {rider},
                  </span>
                  <br />
                  <br />
                  <span className="mt-5">
                    <span className="font-bold">
                      Delivery Location (Address):
                    </span>
                    {address},
                  </span>
                </div>
              </div>

              <div class="sm:text-right space-y-2">
                <div class="grid grid-cols-2 sm:grid-cols-1 gap-3 sm:gap-2">
                  <dl class="grid sm:grid-cols-5 gap-x-3">
                    <dt class="col-span-3 font-semibold text-gray-800 dark:text-gray-200">
                      Published date:
                    </dt>
                    <dd class="col-span-2 text-gray-500">{date}</dd>
                  </dl>
                  <dl class="grid sm:grid-cols-5 gap-x-3">
                    <dt class="col-span-3 font-semibold text-gray-800 dark:text-gray-200">
                      Due date:
                    </dt>
                    <dd class="col-span-2 text-gray-500">{datey}</dd>
                  </dl>
                  <dl class="grid sm:grid-cols-5 gap-x-3">
                    <dt class="col-span-3 font-semibold text-gray-800 dark:text-gray-200">
                      Due Time:
                    </dt>
                    <dd class="col-span-2 text-gray-500">{timey}</dd>
                  </dl>

                  <dl class="grid sm:grid-cols-5 gap-x-3">
                    <dt class="col-span-3 font-semibold text-gray-800 dark:text-gray-200">
                      Status:
                    </dt>
                    <dd class="col-span-2 text-gray-500">
                      {' '}
                      <span className="text-sm px-2 py-1 font-semibold leading-tight text-white bg-red-600 rounded-full">
                        {status}
                      </span>
                    </dd>
                  </dl>
                </div>
              </div>
            </div>

            <div class="mt-6">
              <div class="border border-gray-200 p-4 rounded-lg space-y-4 dark:border-gray-700">
                <div class="hidden sm:block border-b border-gray-200 dark:border-gray-700"></div>
                <div class="relative flex flex-col min-w-0 break-words bg-white border-0 shadow-xl  rounded-2xl bg-clip-border">
                  <form>
                    <div class="border-black/12.5 rounded-t-2xl border-b-0 border-solid p-6 pb-0">
                      <div class="flex items-center">
                        <h4 class=" font-semibold my-3">
                          Add Reason for Dispute
                        </h4>
                        <button
                          onClick={handleSubmit}
                          type="submit"
                          class="inline-block px-8 py-2 mb-4 ml-auto font-bold leading-normal text-center text-white align-middle transition-all ease-in bg-blue-500 border-0 rounded-lg shadow-md cursor-pointer text-xs tracking-tight-rem hover:shadow-xs hover:-translate-y-px active:opacity-85"
                        >
                          Submit Dispute
                        </button>
                      </div>
                    </div>
                    <div class="flex-auto p-6">
                      <div class="flex flex-wrap -mx-3">
                        <div class="w-full max-w-full px-3 shrink-0  md:flex-0">
                          <div class="mb-4">
                            <label class="inline-block mb-2 ml-1 font-bold text-xs text-slate-700 ">
                              Reason for Dispute
                            </label>
                            <textarea
                              type="text"
                              name="reason"
                              class="focus:shadow-primary-outline  text-sm leading-5.6 ease block w-full appearance-none rounded-lg border border-solid border-gray-300 bg-white bg-clip-padding px-3 py-2 font-normal text-gray-700 outline-none transition-all placeholder:text-gray-500 focus:border-blue-500 focus:outline-none"
                              value={reason}
                              onChange={(e) => {
                                setReason(e.target.value);
                              }}
                            >
                              {' '}
                            </textarea>
                          </div>
                        </div>
                      </div>
                      <hr class="h-px mx-0 my-4 bg-transparent border-0 opacity-25 bg-gradient-to-r from-transparent via-black/40 to-transparent  " />
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer />
      </main>
    </>
  );
}

export default BuyAddDispute;
