import React, { useEffect, useState } from 'react';
import Footer from '../../dashboard/footer/Footer';
import Axios from 'axios';

function VenDisputes() {
  const token = localStorage.getItem('usermescrow-Token');
  const [disputes, setDisputes] = useState('');
  const [loading, setLoading] = useState(false);
  const userMobile = localStorage.getItem('usermescrowMobile');

  useEffect(() => {
    Axios.post(
      ' https://us-east4-mescrow.cloudfunctions.net/getalldispute',
      { message: { access_token: token } },

      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => {
        const myDisputes = Object.values(res.data.data);

        const transacts = myDisputes.filter(
          (transacts) => transacts.seller_mobile_no === userMobile,
        );
        // console.log('GEtting  Data ::', res.data.data);
        setLoading(true);

        setDisputes(transacts);
      })
      .catch((err) => console.log(err));
  }, []);

  const myDisputes = Object.values(disputes);
  const dataDisputes = myDisputes.map((data, index) => {
    return (
      <tr
        key={index}
        className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
      >
        <td className="border-grey-light border hover:bg-gray-100 p-3">
          <input
            type="checkbox"
            className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded  "
          />
        </td>

        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          {data.Order_id}
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          {' '}
          {data.seller_mobile_no}
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          {' '}
          {data.Rider_mobile_no}
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          {' '}
          {data.Reason}
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          {' '}
          {data.Location}
        </td>

        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          {data.status ? (
            <span className="text-sm px-2 py-1 font-semibold leading-tight text-white bg-green-600 rounded-full">
              {data.status}
            </span>
          ) : (
            <span className="text-sm px-2 py-1 font-semibold leading-tight text-white bg-red-600 rounded-full">
              {data.Status}
            </span>
          )}
        </td>
        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
          {data.Date}
        </td>
      </tr>
    );
  });

  return (
    <>
      <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
        <div class="w-full px-3 md:px-6 py-6 mx-auto">
          <div class="flex flex-wrap md:mt-64 -mx-3">
            <div class="flex-none w-full max-w-full px-3">
              <div class="relative flex flex-col min-w-0 mb-6 break-words bg-white border-0 border-transparent border-solid shadow-xl  rounded-2xl bg-clip-border">
                <div class="p-6 pb-0 mb-0 border-b-0 border-b-solid rounded-t-2xl border-b-transparent">
                  <h4 class=" font-semibold my-3">My Disputes</h4>
                </div>
                <div class="flex-auto px-0 pt-0 pb-2">
                  <div class="p-0 overflow-x-hidden">
                    <table className="table-sorter table-bordered w-full text-left text-gray-600 ">
                      <thead>
                        <tr className="bg-gray-100  ">
                          <th
                            data-sortable="false"
                            className="hidden lg:table-cell"
                          >
                            <input
                              id="check_all"
                              type="checkbox"
                              className="form-checkbox w-4 h-4 text-indigo-500 border border-gray-300 rounded  "
                            />
                          </th>
                          <th className="hidden lg:table-cell">Order_ID</th>
                          <th className="hidden lg:table-cell">Seller</th>
                          <th className="hidden lg:table-cell">Rider</th>
                          <th className="hidden lg:table-cell">Reason</th>
                          <th className="hidden lg:table-cell">Location</th>
                          <th className="hidden lg:table-cell">Status</th>
                          <th className="hidden lg:table-cell">Date</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          dataDisputes
                        ) : (
                          <tr className="flex justify-center  items-center h-40 w-full">
                            <td className="w-full mx-auto ">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="50"
                                height="50"
                                fill="currentColor"
                                className="bi bi-arrow-repeat animate-spin"
                                viewBox="0 0 16 16"
                              >
                                <path d="M11.534 7h3.932a.25.25 0 0 1 .192.41l-1.966 2.36a.25.25 0 0 1-.384 0l-1.966-2.36a.25.25 0 0 1 .192-.41zm-11 2h3.932a.25.25 0 0 0 .192-.41L2.692 6.23a.25.25 0 0 0-.384 0L.342 8.59A.25.25 0 0 0 .534 9z" />
                                <path d="M8 3c-1.552 0-2.94.707-3.857 1.818a.5.5 0 1 1-.771-.636A6.002 6.002 0 0 1 13.917 7H12.9A5.002 5.002 0 0 0 8 3zM3.1 9a5.002 5.002 0 0 0 8.757 2.182.5.5 0 1 1 .771.636A6.002 6.002 0 0 1 2.083 9H3.1z" />
                              </svg>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}

export default VenDisputes;
