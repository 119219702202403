import React, { useEffect, useState } from 'react';
import Footer from '../../dashboard/footer/Footer';
import Axios from 'axios';

function VenDashboard() {
  const token = localStorage.getItem('usermescrow-Token');
  const [transactions, setTransactions] = useState('');
  const [disputes, setDisputes] = useState('');
  const [buyers, setBuyers] = useState('');
  const [riders, setRiders] = useState('');
  const [revenue, setRevenue] = useState('');
  const userMobile = localStorage.getItem('usermescrowMobile');

  useEffect(() => {
    // get total transactions made
    Axios.post(
      'https://us-central1-mescrow.cloudfunctions.net/stats',
      {
        message: {
          access_token: token,
          call_type: 'transactions',
          variable_field: 'seller_mobile_no',
          variable_value: userMobile,
        },
      },

      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => {
        // console.log('GEtting  Data From transactions ::', res.data.stats);

        setTransactions(res.data.stats);
      })
      .catch((err) => console.log(err));

    // get total Disputes made
    Axios.post(
      'https://us-central1-mescrow.cloudfunctions.net/stats',
      {
        message: {
          access_token: token,
          call_type: 'disputes',
          variable_field: 'seller_mobile_no',
          variable_value: userMobile,
        },
      },

      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => {
        // console.log('GEtting  Data From Disputes ::', res.data.stats);

        setDisputes(res.data.stats);
      })
      .catch((err) => console.log(err));

    // get total buyers
    Axios.post(
      'https://us-central1-mescrow.cloudfunctions.net/stats',
      {
        message: {
          access_token: token,
          call_type: 'buyers',
        },
      },

      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => {
        // console.log('GEtting  Data From Buyers ::', res.data.stats);

        setBuyers(res.data.stats);
      })
      .catch((err) => console.log(err));

    // get total Revenue
    Axios.post(
      'https://us-central1-mescrow.cloudfunctions.net/stats',
      {
        message: {
          access_token: token,
          call_type: 'amount',
          variable_field: 'seller_mobile_no',
          variable_value: userMobile,
          status: 'Paid',
        },
      },

      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => {
        // console.log('GEtting  Data From Revenue ::', res.data.stats);

        setRevenue(res.data.stats);
      })
      .catch((err) => console.log(err));

    // get total Riders
    Axios.post(
      'https://us-central1-mescrow.cloudfunctions.net/stats',
      {
        message: {
          access_token: token,
          call_type: 'riders',
        },
      },

      {
        headers: { 'Content-Type': 'application/json' },
      },
    )
      .then((res) => {
        // console.log('GEtting  Data From Riders ::', res.data.stats);

        setRiders(res.data.stats);
      })
      .catch((err) => console.log(err));
  }, []);

  return (
    <>
      <main class="relative h-full max-h-screen transition-all duration-200 ease-in-out rounded-xl">
        <div class="w-full px-3 md:px-6 py-6 mx-auto">
          <div class="flex flex-wrap md:mt-64 -mx-3">
            <div class="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 md:w-1/3">
              <div class="relative flex flex-col min-w-0 break-words bg-white shadow-xl  rounded-2xl bg-clip-border">
                <div class="flex-auto p-4">
                  <div class="flex flex-row -mx-3">
                    <div class="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p class="mb-0 font-sans text-sm font-semibold leading-normal uppercase ">
                          Transactions
                        </p>
                        <h5 class="mb-2 font-bold md:text-2xl text-xl">
                          {transactions}
                        </h5>
                        <p class="mb-0 ">
                          <span class="text-sm font-bold leading-normal text-emerald-500">
                            +3%
                          </span>
                          Transactions
                        </p>
                      </div>
                    </div>
                    <div class="px-3 text-right basis-1/3">
                      <div class="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                        <i class=" leading-none fas fa-check text-lg relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 md:w-1/3">
              <div class="relative flex flex-col min-w-0 break-words bg-white shadow-xl  rounded-2xl bg-clip-border">
                <div class="flex-auto p-4">
                  <div class="flex flex-row -mx-3">
                    <div class="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p class="mb-0 font-sans text-sm font-semibold leading-normal uppercase ">
                          Disputes
                        </p>
                        <h5 class="mb-2 font-bold md:text-2xl text-xl">
                          {disputes}
                        </h5>
                        <p class="mb-0 ">
                          <span class="text-sm font-bold leading-normal text-red-600">
                            0%
                          </span>
                          Disputes
                        </p>
                      </div>
                    </div>
                    <div class="px-3 text-right basis-1/3">
                      <div class="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-emerald-500 to-teal-400">
                        <i class=" leading-none fas fa-user-shield  text-lg relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 md:w-1/3">
              <div class="relative flex flex-col min-w-0 break-words bg-white shadow-xl  rounded-2xl bg-clip-border">
                <div class="flex-auto p-4">
                  <div class="flex flex-row -mx-3">
                    <div class="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p class="mb-0 font-sans text-sm font-semibold leading-normal uppercase ">
                          Buyers
                        </p>
                        <h5 class="mb-2 font-bold md:text-2xl text-xl">
                          {buyers}
                        </h5>
                        <p class="mb-0 ">
                          <span class="text-sm font-bold leading-normal text-green-600">
                            10%
                          </span>
                          Buyers
                        </p>
                      </div>
                    </div>
                    <div class="px-3 text-right basis-1/3">
                      <div class="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-purple-500 to-indigo-400">
                        <i class=" leading-none fas fa-users  text-lg relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="flex flex-wrap mt-4 -mx-3 mb-10 md:mt-10 ">
            <div class="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none md:w-1/3">
              <div class="relative flex flex-col min-w-0 break-words bg-white shadow-xl  rounded-2xl bg-clip-border">
                <div class="flex-auto p-4">
                  <div class="flex flex-row -mx-3">
                    <div class="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p class="mb-0 font-sans text-sm font-semibold leading-normal uppercase ">
                          Total Revenues
                        </p>
                        <h5 class="mb-2 font-bold md:text-2xl text-xl">
                          KES {revenue}
                        </h5>
                        <p class="mb-0 ">
                          <span class="text-sm font-bold leading-normal text-emerald-500">
                            +5%
                          </span>
                          Revenues
                        </p>
                      </div>
                    </div>
                    <div class="px-3 text-right basis-1/3">
                      <div class="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-orange-500 to-yellow-500">
                        <i class=" leading-none fas fa-piggy-bank text-lg relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="w-full max-w-full px-3 mb-6 sm:w-1/2 sm:flex-none xl:mb-0 md:w-1/3">
              <div class="relative flex flex-col min-w-0 break-words bg-white shadow-xl  rounded-2xl bg-clip-border">
                <div class="flex-auto p-4">
                  <div class="flex flex-row -mx-3">
                    <div class="flex-none w-2/3 max-w-full px-3">
                      <div>
                        <p class="mb-0 font-sans text-sm font-semibold leading-normal uppercase ">
                          Riders
                        </p>
                        <h5 class="mb-2 font-bold md:text-2xl text-xl">
                          {riders}
                        </h5>
                        <p class="mb-0 ">
                          <span class="text-sm font-bold leading-normal text-emerald-500">
                            +2%
                          </span>
                          Delivery
                        </p>
                      </div>
                    </div>
                    <div class="px-3 text-right basis-1/3">
                      <div class="inline-block w-12 h-12 text-center rounded-circle bg-gradient-to-tl from-red-600 to-orange-600">
                        <i class=" leading-none fas fa-shipping-fast text-lg relative top-3.5 text-white"></i>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </main>
    </>
  );
}

export default VenDashboard;
