import React from 'react';

function Page404() {
  return (
    <>
      <div className="relative flex flex-col justify-center min-h-screen overflow-hidden">
        <div className="w-full  m-auto bg-white  rounded shadow-lg ring-2 ring-slate-300/50  lg:max-w-md">
          <div className="text-center p-6 bg-slate-900 rounded-t">
            <a href="index.html">
              <img
                src="/images/logo.png"
                alt="logo"
                className="w-12  mx-auto mb-2"
              />
            </a>
            <h3 className="font-semibold text-white text-xl mb-1">
              Oops! Sorry page does not Exists
            </h3>
          </div>

          <form className="p-6">
            <div className="text-center">
              <img
                src="/images/error.png"
                alt=""
                className="w-32 h-32 block mx-auto my-6"
              />
              <h1 className="font-bold text-7xl ">404!</h1>
              <h5 className="font-medium text-lg text-slate-700">
                The Page Does Not Exist
              </h5>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}

export default Page404;
