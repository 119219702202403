import React from 'react';

function Footer() {
  const newDate = new Date();
  const year = newDate.getFullYear();
  return (
    <>
      <div className="  mt-5 pb-4 ">
        <div className="md:flex justify-center items-center ">
          <img
            src="/images/logo.png"
            className="w-24 mx-auto md:mx-0"
            alt="Logo"
          />
          <p className="text-md pl-5 md:pl-1   text-gray-800 dark:text-white hover:text-gray-700 dark:hover:text-gray-300">
            {' '}
            &copy; {year} Powered by{' '}
            <span className="font-bold"> Msimbo DevOps</span>
          </p>
          {/* <img src="/images/msimbo.PNG" className="w-16" alt="Logo" /> */}
        </div>
      </div>
    </>
  );
}

export default Footer;
