export default function SidenavHeader() {
  const userRole = localStorage.getItem('usermescrowRole');
  return (
    <>
      <div className="bg-white mt-10 flex h-20 items-center justify-center mb-2 sticky top-0 z-10">
        <img src="/images/fav.png" className="w-20" alt="Logo" />
      </div>
      <p className="text-center font-bold uppercase mb-5 border-b">
        {userRole} Dashboard
      </p>
    </>
  );
}
